<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <pre style="display: none">{{ currentRouteName }}</pre>
    <v-snackbar v-model="notifMessage" color="primary" :timeout="5000" :top="true" style="z-index: 5 !important">
      <div @click="goToInbox()">
        <span class="font-sneakbar">
          {{ notificationNeedReply }}
        </span>
      </div>

      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="notifMessage = false">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="expiredMessage" color="error" :timeout="5000" :top="true" style="z-index: 5 !important">
      <span class="font-sneakbar"> Session has expired! </span>

      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="expiredMessage = false">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>

    <!-- v-if="
        user.package !== 'basic' &&
          user.package !== '' &&
          currentRouteName !== 'inbox' &&
          currentRouteName !== 'inbox-phone-number'
      " -->
    <v-snackbar
      v-model="notifMessageDetail"
      :timeout="3000"
      :top="true"
      style="z-index: 1000 !important"
      color="#4a6af4"
    >
      <div @click="goToRoomInbox()">
        <v-row>
          <v-col class="size-col">
            <span class="font-title-sneakbar">
              {{ notificationDetailPerson }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-icon v-if="mediaNotif" size="24">
              {{ getIconNotif }}
            </v-icon>
            <span class="font-body-sneakbar">
              {{ truncateNotifMessage(notificationDetail) }}
            </span>
          </v-col>
        </v-row>
      </div>

      <template #action="{ attrs }">
        <v-btn icon fab small v-bind="attrs" size="24" @click="notifMessageDetail = false">
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>

        <!-- <v-btn
          text
          v-bind="attrs"
          @click="goToRoomInbox()"
        >
          {{ $t('open') }}
        </v-btn> -->
      </template>
    </v-snackbar>
    <!--
    <v-snackbar
      v-model="statusBanner"
      top
      :right="$vuetify.breakpoint.mdAndUp"
      :dark="isDark ? false : true"
      text
      color="warning"
      class="mt-7"
      style="z-index: 5 !important"
    >
      <span>{{ $t('warningfailedsync') }}</span>
    </v-snackbar>

    <v-snackbar
      v-model="statusChannelsBanner"
      :timeout="-1"
      top
      :right="$vuetify.breakpoint.mdAndUp"
      :dark="isDark ? false : true"
      text
      color="warning"
      class="mt-7"
      style="z-index: 5 !important"
    >
      <span>{{ labelStatusInstanceAlert }}</span>
    </v-snackbar>

    <v-snackbar
      v-model="statusServerConnection"
      :timeout="-1"
      top
      :right="$vuetify.breakpoint.mdAndUp"
      :dark="isDark ? false : true"
      text
      color="warning"
      class="mt-7"
      style="z-index: 5 !important"
    >
      {{ $t('serverConnectionLost') }}
      <template #action="{ attrs }">
        <v-btn icon dense v-bind="attrs" class="ml-1" @click="reloadPage()">
          <v-icon>
            {{ icons.mdiRefresh }}
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
-->

    <v-snackbar v-model="isFreeTrial" top :dark="true" text color="info" style="z-index: 5 !important; top: 30px">
      <vue-countdown v-if="isFreeTrial" v-slot="{ days }" :time="freeTrialDiff" @end="onFreeTrialEnd">
        {{ $t('freeTrialRemaining') }}
        <template v-if="days > 0"> {{ days }} {{ $t('days') }} </template>
        <!-- <template v-if="hours > 0">
          {{ hours }} {{ $t('hours') }},
        </template>
        <template v-if="minutes > 0">
          {{ minutes }} {{ $t('mins') }},
        </template>
        <template v-if="seconds >= 0">
          {{ seconds }} {{ $t('seconds') }}.
        </template> -->
        <v-btn x-small :to="{ name: 'subscriptions' }" color="primary"> Subscriptions </v-btn>
      </vue-countdown>
    </v-snackbar>

    <v-dialog v-model="showSnakbar" width="650">
      <v-card class="d-flex flex-column justify-center align-center">
        <v-card-text class="d-flex flex-column justify-center align-center">
          <v-btn fab large color="warning">
            <v-icon large>
              {{ icons.mdiInformation }}
            </v-icon>
          </v-btn>
          <div
            v-if="statusBanner && currentRouteName !== 'inbox' && currentRouteName !== 'inbox-phone-number'"
            class="mt-1"
          >
            {{ $t('warningfailedsync') }}
          </div>
          <!-- <div
            v-if="statusChannelsBanner && currentRouteName !== 'inbox' && currentRouteName !== 'inbox-phone-number' && currentRouteName !== 'subscriptions' && currentRouteName !== 'call-center' && currentRouteName !== 'message-template' && currentRouteName !== 'broadcast-statistic' && currentRouteName !== 'label-list' && currentRouteName !== 'subscriptions' && currentRouteName !== 'subscriptions-plan' && currentRouteName !== 'billings' && currentRouteName !== 'invoice' && currentRouteName !== 'operators-list' && currentRouteName !== 'replies-history' && currentRouteName !== 'operator-statistic' && currentRouteName !== 'invoice-page' && currentRouteName !== 'app-settings' && currentRouteName !== 'sheet-editor'"
            class="mt-1 align-center"
          >
            <p>
              {{ labelStatusInstanceAlert }}
            </p>
          </div> -->

          <div
            v-if="statusServerConnection && currentRouteName !== 'inbox' && currentRouteName !== 'inbox-phone-number'"
            class="mt-1"
          >
            {{ $t('serverConnectionLost') }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="showSnakbar = false">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="checkIsSyncRoom" persistent width="320">
      <v-card color="primary">
        <v-card-text class="pt-3 white--text">
          <!-- <pre>{{ syncProgress }}</pre> -->
          <!-- <pre>{{ progress }}</pre> -->
          <!-- <div v-if="syncProgress && syncProgress.progress > 0">
            <span> Downloading Messages From Whatsapp... ({{ syncProgress.progress }}%) </span>

            <v-progress-linear
              height="5"
              color="white"
              class="mt-2"
              :value="syncProgress.progress"
              :indeterminate="syncProgress.progress === 0"
            >
            </v-progress-linear>
          </div> -->

          <!-- <div> -->
          <span>{{ progressText }} {{ `(${progress})%` }}</span>
          <v-progress-linear height="5" color="white" class="mt-2" :value="progress" />
          <!-- :indeterminate="true" -->
          <!-- :value="progress" -->
          <!-- <span v-if="progressText !== 'Synchronizing to WhatsApp'">{{ progress }}%</span> -->
          <!-- </div> -->
          <!--
          <div v-else>
            <span> Synchronizing Contacts... Please Wait </span>

            <v-progress-linear
              height="5"
              class="mt-2"
              color="white"
              :indeterminate="true"
            >
            </v-progress-linear>
          </div> -->

          <div class="d-flex align-end justify-end">
            <v-btn color="success" class="mt-4" small @click="skipLoad">
              <span>Skip</span>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <!-- <v-col v-if="!$vuetify.breakpoint.mdAndDown"></v-col> -->

      <!-- <v-btn small color="warning" v-if="statusChannelsBanner" @click="showSnakbar = true" class="col-4 col-md-2">
          <v-icon>
            {{ icons.mdiInformation }}
          </v-icon>
        </v-btn>
        <v-btn small color="warning" v-if="statusServerConnection" @click="showSnakbar = true" class="col-2 col-md-2">
          <v-icon>
            {{ icons.mdiInformation }}
          </v-icon>
        </v-btn> -->
      <!-- <div style="width: 10px !important"></div> -->

      <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->

      <div
        style="height: auto"
        class="navbar-content-container justify-space-between"
        :class="{ 'expanded-search': shallShowFullSearch }"
      >
        <!-- Left Content: Search -->
        <v-row>
          <v-col cols="6" class="my-auto">
            <div
              :class="currentRouteName !== 'inbox' && 'inbox-phone-number' ? 'mt-3' : ''"
              class="d-flex align-center my-auto"
            >
              <v-icon v-if="$vuetify.breakpoint.mdAndDown" class="me-3" @click="toggleVerticalNavMenuActive">
                {{ icons.mdiMenu }}
              </v-icon>
              <div class="d-flex my-auto">
                <span
                  v-if="
                    currentRouteName === 'inbox' ||
                    currentRouteName === 'chat-single' ||
                    currentRouteName === 'inbox-phone-number'
                  "
                  class="font-weight-black text-h5 black--text text--black"
                  >Chats</span
                >

                <!-- <v-tooltip
                  v-if="currentRouteName === 'e-ordering' && shops.length !== 0"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="currentRouteName === 'e-ordering'"
                      class="me-2 show-btn"
                      color="primary"
                      fab
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="openAddNewLink()"
                    >
                      <v-icon>
                        {{ icons.mdiStorePlus }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('EOrdering.addShop') }}</span>
                </v-tooltip> -->

                <!-- <v-tooltip
                  v-if="currentRouteName === 'e-ordering' && shops.length !== 0"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="currentRouteName === 'e-ordering'"
                      fab
                      class="show-btn"
                      color="primary"
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="openChatbotSetting"
                    >
                      <v-icon>
                        {{ icons.mdiRobot }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('EOrdering.openChatbotSetting') }}</span>
                </v-tooltip> -->

                <!-- <v-btn
                  v-if="currentRouteName === 'e-ordering' && shops.length !== 0"
                  class="me-2 remove-btn"
                  color="primary"
                  @click="openAddNewLink()"
                >
                  <v-icon
                    dark
                    left
                  >
                    {{ icons.mdiPlusCircle }}
                  </v-icon>
                  {{ $t('EOrdering.addShop') }}
                </v-btn> -->

                <!-- <v-btn
                  v-if="currentRouteName === 'e-ordering' && shops.length !== 0"
                  class="remove-btn"
                  color="primary"
                  @click="openChatbotSetting"
                >
                  <v-icon
                    dark
                    left
                  >
                    {{ icons.mdiCog }}
                  </v-icon>
                  {{ $t('EOrdering.openChatbotSetting') }}
                </v-btn> -->
                <!-- <v-select
                  v-if="
                    (currentRouteName === 'inbox' || currentRouteName === 'inbox-phone-number') &&
                      $vuetify.breakpoint.mdAndUp
                  "
                  v-model="selectedLabel"
                  class="me-2 mt-3"
                  outlined
                  :items="optionsLabel"
                  item-text="label"
                  item-value="id"
                  return-object
                  label="Filters"
                  dense
                  multiple
                  :clearable="true"
                  :disabled="optionsLabel.length === 0"
                  @change="filterLabel"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < maxDisplay"
                      class="me-1"
                    >
                      <v-icon
                        v-if="item.color"
                        dark
                        :style="{ color: item.color }"
                      >
                        {{ icons.mdiLabel }}
                      </v-icon>
                      <v-icon
                        v-if="item.label_color"
                        dark
                        :style="{ color: item.label_color }"
                        class="me-1"
                      >
                        {{ icons.mdiLabelVariant }}
                      </v-icon>
                      {{ $vuetify.breakpoint.xs ? '' : item.label }}
                    </span>
                    <span
                      v-if="index === maxDisplay"
                      class="grey--text caption"
                    >(+{{ selectedLabel.length - maxDisplay }})</span>
                  </template>
                  <template
                    slot="item"
                    slot-scope="{ item }"
                  >
                    <v-icon
                      v-if="item.color"
                      dark
                      :style="{ color: item.color }"
                      class="me-1"
                    >
                      {{ icons.mdiLabel }}
                    </v-icon>
                    <v-icon
                      v-if="item.label_color"
                      dark
                      :style="{ color: item.label_color }"
                      class="me-1"
                    >
                      {{ icons.mdiLabelVariant }}
                    </v-icon>
                    {{ item.label }}
                  </template>
                </v-select>
                <v-select
                  v-if="currentRouteName === 'inbox' || currentRouteName === 'inbox-phone-number'"
                  id="mySelect"
                  v-model="selectedInstanceVal"
                  outlined
                  :items="optionsInstances"
                  item-text="label"
                  label="Channels"
                  dense
                  return-object
                  class="mt-3"
                  hide-details
                  :class="{ 'light-bg': isDark, 'dark-bg': !isDark }"
                  @change="changeInstance"
                >
                  <template v-slot:selection="{ item }">
                    <v-tooltip
                      v-if="bannerAlertText && item.value.status !== 1 && item.label !== 'All Channels'"
                      color="warning"
                      type="info"
                      dense
                      right
                      prominent
                      border="left"
                    >
                      <span class="subtitle-2">{{ bannerAlertText }}</span>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        >
                          <div
                            v-if="item.value.color"
                            class="state-circle"
                            :style="{ 'background-color': item.value.color }"
                          />
                          <span class="warning--text">{{ truncateChannelLabel(item.label) }}</span>
                        </span>
                      </template>
                    </v-tooltip>
                    <div v-if="item.label === 'All Channels' || item.value.status === 1">
                      <div
                        v-if="item.value.color"
                        class="state-circle"
                        :style="{ 'background-color': item.value.color }"
                      />
                      <span>{{ truncateChannelLabel(item.label) }}</span>
                    </div>
                  </template>
                  <template
                    slot="item"
                    slot-scope="{ item }"
                  >
                    <v-tooltip
                      v-if="bannerAlertText && item.value.status !== 1 && item.label !== 'All Channels'"
                      right
                      color="warning"
                      type="info"
                      dense
                      prominent
                      border="left"
                    >
                      <span class="subtitle-2">{{ bannerAlertText }}</span>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        >
                          <div
                            v-if="item.value.color"
                            class="state-circle"
                            :style="{ 'background-color': item.value.color }"
                          />
                          <span class="warning--text">{{ truncateChannelLabel(item.label) }}</span>
                        </span>
                      </template>
                    </v-tooltip>
                    <div v-if="item.label === 'All Channels' || item.value.status === 1">
                      <div
                        v-if="item.value.color"
                        class="state-circle"
                        :style="{ 'background-color': item.value.color }"
                      />
                      <span>{{ truncateChannelLabel(item.label) }}</span>
                    </div>
                  </template>
                </v-select> -->
              </div>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="d-flex align-center justify-end mt-2 mb-2">
              <!-- <AppBarLoanApproval class="mx-4"></AppBarLoanApproval> -->
              <app-bar-i18n></app-bar-i18n>
              <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher>
              <!-- <app-bar-notification
                v-if="!isBasic"
                :notifications="notifications"
                @reset-notifications="resetNotifications"
                @delete-notifications="deleteNotifications"
              ></app-bar-notification> -->
              <app-bar-user-menu></app-bar-user-menu>
            </div>
          </v-col>
          <v-col v-if="currentRouteName !== 'inbox' && currentRouteName !== 'inbox-phone-number'" cols="12">
            <v-alert
              v-if="statusBanner && currentRouteName !== 'inbox' && currentRouteName !== 'inbox-phone-number'"
              class="col-12 my-2 height-costum-alert"
              color="warning"
              dark
            >
              {{ $t('warningfailedsync') }}
            </v-alert>
            <!-- <v-alert
              v-if="statusChannelsBanner && currentRouteName !== 'inbox' && currentRouteName !== 'inbox-phone-number' && currentRouteName !== 'subscriptions' && currentRouteName !== 'call-center' && currentRouteName !== 'message-template' && currentRouteName !== 'broadcast-statistic' && currentRouteName !== 'label-list' && currentRouteName !== 'subscriptions' && currentRouteName !== 'subscriptions-plan' && currentRouteName !== 'billings' && currentRouteName !== 'invoice' && currentRouteName !== 'operators-list' && currentRouteName !== 'replies-history' && currentRouteName !== 'operator-statistic' && currentRouteName !== 'invoice-page' && currentRouteName !== 'app-settings' && currentRouteName !== 'sheet-editor'"
              class="col-12 my-2 height-costum-alert"
              color="warning"
              dark
            >
              {{ labelStatusInstanceAlert }}
            </v-alert> -->
            <v-alert
              v-if="statusServerConnection && currentRouteName !== 'inbox' && currentRouteName !== 'inbox-phone-number'"
              class="col-12 my-2 height-costum-alert"
              color="warning"
              dark
            >
              {{ $t('serverConnectionLost') }}
            </v-alert>
          </v-col>
        </v-row>
      </div>

      <!-- <v-col  cols="3">


          </v-col> -->

      <!-- <div class=" d-flex">




            <div class="d-flex align-center ">

              <app-bar-i18n></app-bar-i18n>
              <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher>
              <app-bar-notification
                v-if="!isBasic"
                :notifications="notifications"
                @reset-notifications="goToInbox"
              ></app-bar-notification>
              <app-bar-user-menu></app-bar-user-menu>
            </div>

        </div> -->

      <!--        <v-btn
          v-if="statusBanner || statusChannelsBanner || statusServerConnection"
          small
          color="warning"
          class="my-2"
          @click="showSnakbar = true"
          block

        >
          &lt;!&ndash;          <v-icon>
            {{ icons.mdiInformation }}
          </v-icon>&ndash;&gt;
          <div>{{ $t('warningfailedsync') }}</div>
          <div>{{ labelStatusInstanceAlert }}</div>
          <div>{{ $t('serverConnectionLost') }}</div>
        </v-btn>-->

      <!--        <div style="width: 100%; height: 50px; background-color: chocolate; margin: 10px"></div>-->
      <!-- </div> -->
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div
        v-if="currentRouteName !== 'inbox' && currentRouteName !== 'inbox-phone-number'"
        class="d-flex justify-space-between"
      >
        <span
          >COPYRIGHT &copy; {{ new Date().getFullYear() }}
          <a href="https://marketa.id" class="text-decoration-none">Happyhomes Chat</a
          ><span class="d-none d-md-inline">, All rights Reserved</span>
        </span>
        <div v-if="versionData" class="align-center d-none d-md-flex">
          <span>{{ $t('version') }} {{ versionData.data }}</span>
        </div>
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
/* eslint-disable */
// Search Data
import appBarSearchData from '@/assets/app-bar-search-data'
import AppBarI18n from '@/components/AppBarI18n.vue'
import AppBarLoanApproval from '@/components/AppBarLoanApproval.vue'
import AppBarNotification from '@/components/AppBarNotification.vue'
import AppBarUserMenu from '@/components/AppBarUserMenu.vue'
import { db } from '@/helpers/db.js'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNavCustom'
import channelMixin from '@/mixins/channelMixin'
import eOrderingMixin from '@/mixins/e-orderingMixin'
import firestoreDbMixin from '@/mixins/firestoreDbMixin'
import inboxMixin from '@/mixins/inboxMixin'
import pouchDbMixin from '@/mixins/pouchDbMixin'
import navMenuItems from '@/navigation/vertical'
import { loadLanguageAsync } from '@/plugins/i18n'
import AddEOrder from '@/views/e-ordering/create.vue'
import VueCountdown from '@chenfengyuan/vue-countdown'
import useAppConfig from '@core/@app-config/useAppConfig'
import { useObservable } from '@vueuse/rxjs'
import { liveQuery } from 'dexie'
// App Bar Components

//! Capacitor Dependencies
import { Capacitor } from '@capacitor/core'
import { LocalNotifications } from '@capacitor/local-notifications'
import { PushNotifications } from '@capacitor/push-notifications'
import { FCM } from '@capacitor-community/fcm'

// ===============================================================
import { default as clientPocketBase } from '@/helpers/pocketbase'
import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import { getVuetify } from '@core/utils'
import {
  mdiAccount,
  mdiAccountMultiple,
  mdiAccountQuestion,
  mdiCamera,
  mdiCardAccountDetails,
  mdiChatQuestion,
  mdiClose,
  mdiCog,
  mdiFileDocument,
  mdiHeadset,
  mdiHeartBox,
  mdiHeartOutline,
  mdiInformation,
  mdiKeyboardVariant,
  mdiKeyWireless,
  mdiLabel,
  mdiLabelVariant,
  mdiMenu,
  mdiMessage,
  mdiMessageArrowRight,
  mdiMicrophone,
  mdiPlusCircle,
  mdiRefresh,
  mdiRobot,
  mdiShopping,
  mdiShoppingSearch,
  mdiStorePlus,
  mdiVideo,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import _orderBy from 'lodash/orderBy'
import _uniqBy from 'lodash/uniqBy'

export default {
  mixins: [firestoreDbMixin, pouchDbMixin, channelMixin, inboxMixin, eOrderingMixin],
  components: {
    LayoutContentVerticalNav,
    // App Bar Components
    AppBarSearch,
    AppBarUserMenu,
    AppBarI18n,
    AppBarThemeSwitcher,
    AppBarNotification,
    VueCountdown,
    AddEOrder,
    AppBarLoanApproval,
  },
  watch: {
    // getLabelFilter(newVar) {
    //   //console.log(newVar, `INI NEW VAR`)
    //   // this.selectedLabel = newVar
    // },
    // notifMessageDetail(val) {
    //   if(!val) {
    //     this.notificationDetail = ''
    //   }
    // },
  },
  computed: {
    newChatContent() {
      return this.$store.getters['inbox/getNewChatContent']
    },
    currentOffset() {
      return this.$store.getters['inbox/getCurrentOffset']
    },
    allContactData() {
      return this.$store.getters['global/getAllContactData']
    },
    lastInserted() {
      return this.$store.getters['global/getLastInserted']
    },
    getLabelvalue() {
      return this.$store.getters['inbox/getLabelValue']
    },
    optionsLabel() {
      // //console.log(this.labelValue, this.getLabelvalue, this.labelData, `DATA LABEL`)
      const listLabel = []
      const filtered = this.getLabelvalue.filter(x => x.contact && !x.chat)
      // eslint-disable-next-line no-restricted-syntax
      for (const data of filtered) {
        // eslint-disable-next-line
        const label = this.labelData.find(x => x.label == data.label.id)

        if (label) {
          listLabel.push(label)
        } else {
          listLabel.push(data.label)
        }
      }
      return _uniqBy([...listLabel, ...this.contactList], 'id')
    },
    statusOperator() {
      return this.$store.getters['rooms/getstatusOperator']
    },
    currentScanned() {
      // //console.log(this.$store.getters['global/getCurrentScanned'], `INI GLOBAL SCANNED`)
      return this.$store.getters['global/getCurrentScanned']
    },
    loadingMessages() {
      return this.$store.getters['global/getLoadingMessages']
    },
    getIconNotif() {
      // //console.log(`media notif`, this.mediaNotif)
      if (this.mediaNotif === 'Sticker') {
        return this.icons.mdiHeartBox
      }
      if (this.mediaNotif === 'Video') {
        return this.icons.mdiVideo
      }
      if (this.mediaNotif === 'Photo') {
        return this.icons.mdiCamera
      }
      if (this.mediaNotif === 'Document') {
        return this.icons.mdiFileDocument
      }

      return this.icons.mdiMicrophone
    },
    getLabelFilter() {
      return this.$store.getters['inbox/getLabelFilter']
    },
    shops() {
      // this.shopList.forEach(el => {
      //   if (this.totalShop !== this.shopList.length) {
      //     this.menu[`menu${this.shopList.length - 1}`] = false
      //     this.dates.push([
      //       new Date(new Date().setDate(new Date().getDate() - 10)).toISOString().substring(0, 10),
      //       new Date().toISOString().substring(0, 10),
      //     ])
      //   }
      // })

      const filteredShops = this.shopList.map(el => {
        el.data.id = el._id

        return el.data
      })

      if (filteredShops.length !== 0) {
        return filteredShops
      }
      return []
    },
    optionsInstances() {
      // const instances = this.$store.getters['auth/getListInstance']
      // //console.log('instances: ', this.user)
      if (!this.instances)
        return [
          {
            label: 'All Channels',
            value: {
              all_sender: true,
              phone_number: null,
              color: '#3fc19f',
            },
          },
        ]
      const listInstance = []
      const listInstanceOperator = []
      const all = {
        label: 'All Channels',
        value: {
          all_sender: true,
          phone_number: null,
          color: '#3fc19f',
        },
      }

      for (const data of this.instances.value) {
        if (
          this.user.enabled_channel.length !== 0 &&
          (this.user.enabled_channel.includes(data._id) || this.user.enabled_channel.includes(data.phone_number))
        ) {
          listInstanceOperator.push({
            label:
              data.label.startsWith('-') || data.label.includes('Default') || data.label.includes('62')
                ? data.label_server
                : data.label,
            value: data,
          })
        }

        listInstance.push({
          label:
            data.label.startsWith('-') || data.label.includes('Default') || data.label.includes('62')
              ? data.label_server
              : data.label,
          value: data,
        })
      }
      // //console.log(
      //   'listInstanceOperator: ',
      //   listInstanceOperator,
      //   listInstance,
      //   this.user.enabled_channel.length,
      //   this.instances,
      // )

      if (listInstance.length !== 0 || listInstanceOperator.length !== 0) {
        listInstance.unshift(all)
        listInstanceOperator.unshift(all)
      }

      if (this.getGlobalChannel) {
        this.selectedInstanceVal = this.getGlobalChannel
      }
      if (this.user.enabled_channel.length !== 0) return listInstanceOperator

      return listInstance
    },
    bannerAlertText() {
      return this.$store.getters['inbox/getBannerAlertText']
    },
    // isNotInbox() {
    //   return window.location.pathname !== '/inbox'
    // },
    getNotifAllOrAssigned() {
      return this.$store.getters['global/getNotifAllOrAssigned']
    },
    currentRouteName() {
      // //console.log(this.$route.name, 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaa')

      return this.$route.name
    },
    getGlobalChannel() {
      return this.$store.getters['global/getGlobalChannel']
    },
    getAutoAssignNewChat() {
      return this.$store.getters['global/getAutoAssignNewChat']
    },
    isBasic() {
      return this.user && this.user.package === 'basic'
    },
    freeTrialDiff() {
      // return 1000
      let diff = 0

      if (this.user && this.user.is_free_trial && this.user.expire_date) {
        const now = this.$moment()
        const expireDate = this.$moment.unix(this.user.expire_date / 1000)
        diff = expireDate.diff(now)
      }

      return diff
    },
    currentVersion() {
      return this.$store.getters['global/getVersion']
    },
    muteValue() {
      return this.$store.getters['global/getMuteValue']
    },
    labelStatusInstanceAlert() {
      // if (!this.instancesSetting) return ''
      const labels = []

      if (this.instanceValues) {
        this.instanceValues.value.forEach(instance => {
          if ((instance.status == 0 || instance.status == 3) && instance.phone_number) {
            if (this.user.limited_channel) {
              if (this.user.enabled_channel && this.user.enabled_channel.find(y => y === instance._id)) {
                labels.push(instance.label === '-' ? instance.label_server : instance.label)
              }
            } else {
              labels.push(instance.label === '-' ? instance.label_server : instance.label)
            }
          }
        })
      }

      if (labels.length > 0) {
        return `Channel ${labels.join(', ')} is not connected, please check your device connection`
      }

      return ''
    },
    statusChannelsBanner() {
      return this.$store.getters['global/getStatusChannels']
    },
    lang() {
      return this.$store.getters['global/getLang']
    },
    statusBanner() {
      return !this.$store.getters['auth/isSuccessSync']
    },
    statusServerConnection() {
      return !this.$store.getters['global/getServerConnectionStatus']
    },
    contactsVuex() {
      return this.$store.getters['global/getContacts']
    },
    // instances() {
    //   if (this.instancesSetting) {
    //     return this.instancesSetting.value
    //   }

    //   return []
    // },
    instanceValues() {
      // const instances = this.$store.getters['auth/getListInstance']

      // if (instances) {
      //   if (!instances.value) {
      //     instances.value = []
      //   }
      // }

      return this.instances
    },
    instanceValuesConnected() {
      let instances = []

      if (this.instanceValues && this.instanceValues.value && this.instanceValues.value.length > 0) {
        for (const key in this.instanceValues.value) {
          const instance = this.instanceValues.value[key]

          if (instance.status == 1) {
            instances.push(instance._id)
          }
        }
      }

      return instances
    },
    notificationNeedReply() {
      return `${this.$t('notificationNeedReplyOne')} ${this.contactNeedReply} ${this.$t('notificationNeedReplyTwo')}`
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    isCustomerGroupChanged() {
      return this.$store.getters['global/getIsCustomerGroupChanged']
    },
    isScanning() {
      return this.$store.getters['global/getIsScanning']
    },
  },
  setup() {
    const $vuetify = getVuetify()
    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })
    // NOTE: Update search function according to your usage
    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true
      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'
        return null
      })()
      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }
      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }
    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }
    const { isDark } = useAppConfig()
    return {
      navMenuItems,
      handleShallShowFullSearchUpdate,
      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,
      icons: {
        mdiLabel,
        mdiLabelVariant,
        mdiMicrophone,
        mdiVideo,
        mdiFileDocument,
        mdiCamera,
        mdiHeartBox,
        mdiMenu,
        mdiHeartOutline,
        mdiRefresh,
        mdiInformation,
        mdiClose,
        mdiCog,
        mdiPlusCircle,
        mdiKeyWireless,
        mdiKeyboardVariant,
        mdiMessage,
        mdiMessageArrowRight,
        mdiAccount,
        mdiHeadset,
        mdiShopping,
        mdiShoppingSearch,
        mdiAccountMultiple,
        mdiCardAccountDetails,
        mdiAccountQuestion,
        mdiChatQuestion,
        mdiStorePlus,
        mdiRobot,
      },
      isDark,
    }
  },
  data() {
    return {
      platform: '',
      notificationId: 1,
      channelNotification: '',
      rooms: useObservable(liveQuery(() => db.rooms.toArray())),
      showedRooms: useObservable(liveQuery(() => db.showedRooms.toArray())),
      maxDisplay: 1,
      selectedLabel: null,
      checkIsSyncRoom: false,
      test: true,
      loadingSync: [],
      syncProgress: {
        progress: 0,
        loading_sync_history: true,
        loading_sync_contacts: true,
      },
      progress: 0,
      progressText: 'Synchronizing to WhatsApp',
      mediaNotif: '',
      dialogChatbotSetting: false,
      chatbotSetting: {
        greetingMsg: '',
        talkToCustMsg: '',
        selectedShop: null,
        qnaMsg: '',
        instanceId: '',
        keyword: '',
        keywordActive: false,
        isActive: true,
      },
      addEorder: false,
      shopData: null,
      expiredMessage: false,
      attrs: '',
      // channel
      selectedInstanceVal: {
        label: 'All Channels',
        value: {
          all_sender: true,
          phone_number: null,
          color: '#3fc19f',
        },
      },
      roomId: '',
      notificationDetailPerson: '',
      notificationDetailPhoneNumber: null,
      notificationDetail: '',
      notifMessageDetail: false,
      allChatsCouch: [],
      selectedNotifUnreplied: null,
      contactNeedReply: 0,
      notifMessage: false,
      sound: '',
      intervalId: null,
      intervalScan: null,
      intervalIdCheckHealth: null,
      intervalPingOps: null,
      notifyInterfall: null,
      notifications: [],
      isFreeTrial: false,
      instances: {
        value: [],
      },
      boldUnreplied: false,
      autoAssignChatOnReply: true,
      autoAssignNewChat: true,
      notifAllOrAssigned: { name: this.$t('notifFromChannel'), data: 2 },
      labelValue: [],
      labelData: [],
      contactList: [],
      // DB SETTING
      // instancesSetting: null,
      lastConnectionStatus: false,
      // optionsInstances: [],
      selectedInstance: {
        label: 'All Channels',
        value: {
          all_sender: true,
          phone_number: null,
          color: '#3fc19f',
        },
      },
      versionData: null,
      // CONTACTS
      contactsPouch: [],
      lastNotif: null,
      showSnakbar: false,
      getIframeSrcCount: 0,
      databaseChangesRooms: null,
      databaseChanges: null,
      // lastInserted: null,
      isDoingScan: false,
      lastMessageNotif: {
        content: '',
        sender: '',
        time: 0,
      },
      lastActiveTime: null,
    }
  },
  beforePageDestroyed() {},
  async beforeDestroy() {
    if (this.intervalId) clearInterval(this.intervalId)
    if (this.intervalIdCheckHealth) clearInterval(this.intervalIdCheckHealth)
    if (this.notifyInterfall) clearInterval(this.notifyInterfall)
    if (this.intervalPingOps) clearInterval(this.intervalPingOps)
    if (this.intervalScan) clearInterval(this.intervalScan)

    // this.databaseChanges.unsubscribe()
    // this.databaseChangesSettings.unsubscribe()
    this.databaseChanges = null
    this.databaseChangesSettings = null
    // this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_CONTACTS)
    // this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_CHAT)

    LocalNotifications.removeAllListeners('localNotificationActionPerformed')

    this.setOnlineFalse()
  },
  watch: {
    async newChatContent(newVal) {
      if (newVal) {
        await this.updateNotif(newVal)
        this.$store.commit('inbox/setNewChatContent', null)
      }
    },
    async checkIsSyncRoom(val) {
      if (!val) {
        this.$store.commit('global/setIsScanning', false)
        // const listInstances = await clientPocketBase.records.getFullList('instances', 20, {
        //   filter: `sub_id = '${this.user.sub_id}'`,
        // })

        // const instanceIds = listInstances.map(el => this.$Md5Hash(el._id))
        // //console.log(instanceIds, 'ini instancevvv<<<')
        // await this.$store.dispatch('global/findAllContactData', {
        //   sub_id: this.user.sub_id,
        //   instances: instanceIds,
        // })
      }
    },
    async progress(newVal) {
      if (newVal === 100) {
        if (this.intervalScan) clearInterval(this.intervalScan)
        const isBcGroup = this.currentScanned.broadcast_group
        this.$store.commit('global/setCurrentScanned', null)
        this.$store.commit('global/setIsScanning', false)
        this.isDoingScan = false
        if (!isBcGroup) {
          window.location.reload()
        }
      }
    },
    currentScanned(newVal) {
      if (newVal) {
        this.$store.commit('global/setIsScanning', true)
        this.lastInserted = 0
        this.isDoingScan = true
        this.checkIsSyncRoom = true
        // //console.log(this.isDoingScan, this.isScanning, 'ini valuenya apa')
        this.intervalScan = this.startInterval()
        // this.intervalScan = setInterval(() => {
        //   if (parent.progress <= 100) {
        //     parent.progress += 2
        //   }
        //   if(parent.progress === 60) {
        //     interval = 2000
        //   }
        //   // else if (parent.progress === 100) {
        //   //   if (parent.intervalScan) clearInterval(parent.intervalScan)
        //   //   this.$store.commit('global/setCurrentScanned', null)
        //   // }
        // }, interval)
      } else if (!newVal) {
        this.checkIsSyncRoom = false
        this.progress = 0
      }
    },
    currentRouteName(newVar) {
      // const REALTIME_URL = 'wss://realtime.superwa.io/socket'
      // const client = new RealtimeClient(REALTIME_URL)
      // client.connect()
      // client.onOpen(() => console.log('Socket opened.'))
      // client.onClose(() => console.log('Socket closed.'))
      // client.onError(e => console.log('Socket error', e))
      if (newVar !== 'inbox' && newVar !== 'inbox-phone-number') {
        // this.databaseChangesRooms = client.channel(`realtime:${this.user.sub_id}:rooms`)
        // this.databaseChangesRooms.on('INSERT', e => this.updateRooms(e))
        // this.databaseChangesRooms.on('UPDATE', e => this.updateRooms(e))
        // this.databaseChangesRooms.subscribe()
        // clientPocketBase.realtime.subscribe('contact', e => this.eventUpdateRoom(e))
      } else if (newVar == 'inbox' || newVar == 'inbox-phone-number') {
        // clientPocketBase.realtime.unsubscribe('contact')
        // this.databaseChangesRooms.unsubscribe()
        // this.databaseChangesRooms = null
      }

      // //console.log(
      //   '🚀 ~ file: LayoutContentVerticalNav.vue ~ line 1358 ~ currentRouteName ~ this.databaseChangesRooms',
      //   this.databaseChangesRooms,
      // )
    },
    getLabelFilter(newVar) {
      // //console.log(newVar, `INI NEW VAR`)
      this.selectedLabel = newVar
    },
    loadingMessages(newVar) {
      // //console.log(newVar, `INI NEWVAR NYA`)
      this.checkIsSyncRoom = newVar
    },
    async statusServerConnection(newVal) {
      if (!this.lastConnectionStatus && !newVal) {
        // await this.connectSettings(false)
        await this.loadSetting()
      }
      this.lastConnectionStatus = !newVal
    },
    statusChannelsBanner(data) {
      this.setAlertChannelText()
    },
    async isCustomerGroupChanged(varnew) {
      // //console.log(varnew, 'ini varnew')
      if (varnew) {
        const contactListPg = await this.$store.dispatch('global/getContactLists', {
          sub_id: this.user.sub_id,
          token: this.user.token,
        })

        if (contactListPg.status) {
          this.contactList = contactListPg.data.filter(list => {
            if (list.list_id !== 'default') {
              list.id = list.list_id
              list.label = list.list_name
              return true
            } else if (list.list_id === 'default') {
              list.id = list.list_id
              list.label = 'All Costumer'
              return true
            }

            return false
          })
        }
        await this.$store.commit('global/setIsCustomerGroupChanged', false)
      }
    },
  },
  created(){
    console.log("MOUNTED:ISLOGIN:"+clientPocketBase.authStore.isValid);
    clientPocketBase.collection('users').authRefresh()
    .catch(() => {
      this.logout(true);
    });
    // Save the initial active time
    this.lastActiveTime = Date.now();

    // Attach event listeners for window focus and blur events
    window.addEventListener('focus', this.handleWindowFocus);
    window.addEventListener('blur', this.handleWindowBlur);
  },
  destroyed() {
    // Clean up event listeners when the component is destroyed
    window.removeEventListener('focus', this.handleWindowFocus);
    window.removeEventListener('blur', this.handleWindowBlur);
  },
  async mounted() {
    this.platform = Capacitor.getPlatform()
    console.log(
      `🥶🥶🥶🥶 ~ フ ク ロ ウ file: LayoutContentVerticalNav.vue:1321 ~ フ ク ロ ウ mounted ~ フ ク ロ ウ platform:`,
      this.$vuetify,
    )
    if (this.platform === 'android' || this.platform === 'ios') {
      await this.addListeners()
      await this.registerNotification()

      this.channelNotification = this.$nanoid()

      await LocalNotifications.createChannel({
        id: this.channelNotification,
        name: 'Happyhomes Chat',
        description: 'Happyhomes Chat App',
        importance: 5,
        sound: 'ting.wav',
        vibration: true,
        visibility: 'public',
      })

      // Register the reply action
      await LocalNotifications.registerActionTypes({
        types: [
          {
            id: 'reply',
            actions: [{ id: 'reply', title: 'Reply', input: true, inputButtonTitle: 'Send' }],
          },
        ],
      })
    }

    await this.requestNotificationPermission()

    LocalNotifications.addListener('localNotificationActionPerformed', async notification => {
      // Handle the notification action here
      // console.log('Notification action performed:', JSON.stringify(notification))
      // You can access the notification action details from the 'notification' parameter
      // For example, if you want to get the action ID:
      const actionId = notification.actionId

      if (actionId === 'reply') {
        const id = notification.notification.id
        // Or if you want to get any additional data passed with the action:
        const room = notification.notification.extra.room
        const user = notification.notification.extra.user
        const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}chats/send?id=${user.sub_id}-${
          room.contact_id.split('-')[0]
        }`
        const formData = new FormData()

        formData.append('receiver', room.contact_id.split('-')[1])
        formData.append('message', notification.inputValue)
        formData.append('operator', user.email)

        try {
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
            timeout: 120000,
          }

          // //console.log(formData, recipient_id, roomId, 'ini formz')
          const { data } = await this.$axios.post(endpoint, formData, config)
        } catch (err) {
          console.log(
            `🥶🥶🥶🥶 ~ フ ク ロ ウ file: LayoutContentVerticalNav.vue:1366 ~ フ ク ロ ウ mounted ~ フ ク ロ ウ err:`,
            err,
          )
        }

        // ...
      }
    })

    if (Array.isArray(this.getLabelFilter)) {
      this.$store.commit('inbox/setLabelFilter', null)
    }

    const listInstances = await clientPocketBase.collection('crm_instances').getFullList(20, {
      filter: `sub_id = '${this.user.sub_id}'`,
    })

    if (listInstances) {
      this.instances.value = listInstances
    }

    this.$store.commit('global/setIsScanning', false)

    window.addEventListener('beforeunload', this.handler)

    loadLanguageAsync(this.lang)
    const conn = await this.checkStatusConnectionServer()
    if (conn) {
      this.$store.commit('global/setServerConnectionStatus', conn)
    }
    this.lastConnectionStatus = conn

    const query = {
      token: this.user.token,
      sub_id: this.user.sub_id,
    }

    this.isFreeTrial = this.user && this.user.is_free_trial

    let doc = document.getElementsByClassName('height-costum-alert')[0]

    window.addEventListener('resize', () => {
      doc = document.getElementsByClassName('height-costum-alert')[0]
      if (doc) {
        this.$store.commit('inbox/setStyleHeight', doc.clientHeight)
      }
    })

    if (doc) {
      this.$store.commit('inbox/setStyleHeight', doc.clientHeight)
    }

    this.setAlertChannelText()
  },
  methods: {
    handleWindowFocus() {
      // Calculate the duration in milliseconds since the component was last active
      const inactiveDuration = Date.now() - this.lastActiveTime;
      console.log("INACTIVE:"+inactiveDuration);
      // Check if the component was inactive for more than 5 minutes (300000 milliseconds)
      if (inactiveDuration > 300000) {
        // Reload the page
        location.reload();
      }
    },
    handleWindowBlur() {
      // Save the time when the component becomes inactive
      this.lastActiveTime = Date.now();
    },
    async registerNotification(payload) {
      let permStatus = await PushNotifications.checkPermissions()
      console.log(
        `🥶🥶🥶🥶 ~ フ ク ロ ウ file: LayoutContentVerticalNav.vue:1672 ~ フ ク ロ ウ registerNotification ~ フ ク ロ ウ permStatus:`,
        permStatus,
      )

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions()
      }

      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!')
      }

      await PushNotifications.register({
        forcePermissionPrompt: true,
        hideForegroundAlert: true,
      })
    },
    async addListeners() {
      await PushNotifications.addListener('registration', async token => {
        console.info('Registration token: ', token.value)
        if (this.platform === 'ios') {
          FCM.getToken()
            .then(async r => {
              console.log(`token saved ${r.token}`)
              try {
                await clientPocketBase.collection('crm_notification_devices').update(
                  this.$Md5Hash(`${r.token}-${this.user.id}`),
                  {
                    token: r.token,
                    user: this.user.id,
                    instances: this.$store.getters['auth/getListInstance'].value.map(el => el.id),
                  },
                  {
                    $autoCancel: false,
                  },
                )
              } catch (err) {
                console.log(
                  `🥶🥶🥶🥶 ~ フ ク ロ ウ file: LayoutContentVerticalNav.vue:1791 ~ フ ク ロ ウ addListeners ~ フ ク ロ ウ err:`,
                  err,
                )

                try {
                  await clientPocketBase.collection('crm_notification_devices').create(
                    {
                      id: this.$Md5Hash(`${r.token}-${this.user.id}`),
                      token: r.token,
                      user: this.user.id,
                      instances: this.$store.getters['auth/getListInstance'].value.map(el => el.id),
                    },
                    {
                      $autoCancel: false,
                    },
                  )
                } catch (err) {
                  console.log(
                    `🥶🥶🥶🥶 ~ フ ク ロ ウ file: LayoutContentVerticalNav.vue:1803 ~ フ ク ロ ウ addListeners ~ フ ク ロ ウ err:`,
                    err,
                  )
                }
              }
            })
            .catch(err => console.warn('error saving token', err))
        } else {
          try {
            await clientPocketBase.collection('crm_notification_devices').update(
              this.$Md5Hash(`${token.value}-${this.user.id}`),
              {
                token: token.value,
                user: this.user.id,
                instances: this.$store.getters['auth/getListInstance'].value.map(el => el.id),
              },
              {
                $autoCancel: false,
              },
            )
          } catch (err) {
            console.log(
              `🥶🥶🥶🥶 ~ フ ク ロ ウ file: LayoutContentVerticalNav.vue:1791 ~ フ ク ロ ウ addListeners ~ フ ク ロ ウ err:`,
              err,
            )

            try {
              await clientPocketBase.collection('crm_notification_devices').create(
                {
                  id: this.$Md5Hash(`${token.value}-${this.user.id}`),
                  token: token.value,
                  user: this.user.id,
                  instances: this.$store.getters['auth/getListInstance'].value.map(el => el.id),
                },
                {
                  $autoCancel: false,
                },
              )
            } catch (err) {
              console.log(
                `🥶🥶🥶🥶 ~ フ ク ロ ウ file: LayoutContentVerticalNav.vue:1803 ~ フ ク ロ ウ addListeners ~ フ ク ロ ウ err:`,
                err,
              )
            }
          }
        }
      })

      await PushNotifications.addListener('registrationError', err => {
        console.error('Registration error: ', err.error)
      })

      await PushNotifications.addListener('pushNotificationReceived', notification => {
        console.log('Push notification received: ', notification)
      })

      await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        console.log('Push notification action performed', notification.actionId, notification.inputValue)
      })
    },
    async requestNotificationPermission() {
      try {
        const permissionResult = await LocalNotifications.checkPermissions()
        console.log(
          `🥶🥶🥶🥶 ~ フ ク ロ ウ file: LayoutContentVerticalNav.vue:1662 ~ フ ク ロ ウ requestNotificationPermission ~ フ ク ロ ウ permissionResult:`,
          JSON.stringify(permissionResult),
        )
        if (permissionResult.display !== 'granted') {
          const requestResult = await LocalNotifications.requestPermissions()
          if (requestResult.display !== 'granted') {
            // Handle permission not granted
            return
          }
        }

        // Permission granted, you can now schedule and display notifications
      } catch (error) {
        // Handle error
      }
    },
    async eventUpdateRoom({ record, action }) {
      // if (action !== 'delete' && record.type === 'rooms') {
      //   await this.updateNotif(record)
      // }
    },
    startInterval(interval = 450, addition = 1) {
      const intervalId = setInterval(async () => {
        if (this.progress === 99) {
          this.progress += 1
        } else {
          this.progress += addition
        }
        // if (this.progress < 96) {
        //   this.progress += addition
        // }
        // if(this.progress > 50) {
        //   const time = new Date().getTime()
        //   //console.log(time, this.lastInserted, time - this.lastInserted, 'ini dia timenya<<<<')
        //   if(this.lastInserted && (time - this.lastInserted > 10000)) {
        //     const listInstances = await clientPocketBase.records.getFullList('instances', 20, {
        //       filter: `sub_id = '${this.user.sub_id}'`,
        //     })

        //     const instanceIds = listInstances.map(el => this.$Md5Hash(el._id))
        //     await this.$store.dispatch('global/findAllContactData', {
        //       sub_id: this.user.sub_id,
        //       instances: instanceIds,
        //     })
        //     this.$store.commit('global/setIsScanning', false)
        //     this.progress = 100
        //   }
        // }
        // else if (parent.progress === 100) {
        //   if (parent.intervalScan) clearInterval(parent.intervalScan)
        //   this.$store.commit('global/setCurrentScanned', null)
        // }
      }, interval)
      return intervalId
    },
    async updateNotif(record) {
      let newChat = false
      if (record.unread_count) {
        newChat = true
      }
      // //console.log(record, 'ini tes')
      // const indexChat = this.allChatsCouch.findIndex(x => x.roomId === record.roomId)

      // if (indexChat > -1 && this.allChatsCouch[indexChat] && this.allChatsCouch[indexChat].lastMessage) {
      //   newChat = this.allChatsCouch[indexChat].lastMessage.source_id !== record.lastMessage.source_id
      //   // //console.log(
      //   //   '🚀 ~ file: LayoutContentVerticalNav.vue ~ line 1613 ~ newChat',
      //   //   this.allChatsCouch[indexChat].lastMessage.source_id !== record.lastMessage.source_id,
      //   //   record.lastMessage.source_id,
      //   //   this.allChatsCouch[indexChat].lastMessage.source_id,
      //   //   this.allChatsCouch[indexChat].lastMessage,
      //   //   record.lastMessage,
      //   //   newChat,
      //   // )
      // }
      if (newChat) {
        let content = record.lastMessage.content
        this.mediaNotif = ''

        if (record.lastMessage.files.length !== 0 && record.lastMessage.files.find(el => el.type === 'webp')) {
          this.mediaNotif = 'Sticker'
        } else if (
          record.lastMessage.files.length !== 0 &&
          record.lastMessage.files.find(el => el.name === 'Photo') &&
          record.lastMessage.files.find(el => !el.audio)
        ) {
          this.mediaNotif = 'Photo'
        } else if (record.lastMessage.files.length !== 0 && record.lastMessage.files.find(el => el.name === 'Video')) {
          this.mediaNotif = 'Video'
        } else if (record.lastMessage.files.length !== 0 && record.lastMessage.data.type === 'document') {
          this.mediaNotif = 'Document'
        } else if (
          record.lastMessage.files.length !== 0 &&
          record.lastMessage.files.find(el => el.name === 'Audio') &&
          record.lastMessage.files.find(el => el.audio)
        ) {
          this.mediaNotif = 'Audio'
        }
        this.notificationDetailPerson = this.getContactName(record.phone_number, record.name)
        this.notificationDetail = this.formatTags(content, record) || this.mediaNotif

        this.notificationDetailPhoneNumber = record.lastMessage.sender_id

        if (
          this.lastMessageNotif.content &&
          this.lastMessageNotif.content === this.notificationDetail &&
          this.lastMessageNotif.sender === this.notificationDetailPhoneNumber &&
          this.lastMessageNotif.time === record.lastMessage.timestamp._seconds
        ) {
          return
        }
        this.lastMessageNotif.content = JSON.parse(JSON.stringify(this.notificationDetail))
        this.lastMessageNotif.sender = JSON.parse(JSON.stringify(this.notificationDetailPhoneNumber))
        this.lastMessageNotif.time = record.lastMessage.timestamp._seconds
        this.roomId = record.roomId
        // //console.log('🚀 ~ file: LayoutContentVerticalNav.vue ~ line 1626 ~ record', record)
        this.$store.commit('inbox/setNewChat', false)

        if (
          !record.lastMessage.fromMe &&
          !this.loadingMessages &&
          new Date().getTime() - record.lastMessage.timestamp._seconds * 1000 <= 60000
        ) {
          if (
            this.user.chat_mode === 'Read and Write to assigned only' &&
            (!record.assign_to || record.expand?.assign_to?.email === this.user.email)
          ) {
            this.play()
            this.lastNotif = new Date().getTime() / 1000
            this.notifMessageDetail = true
            this.$store.commit('inbox/setNewChat', true)
            if (this.platform === 'web') {
              const canSend = await LocalNotifications.requestPermissions()
              console.log('🚀 ~ file: LayoutContentVerticalNav.vue ~ line 1336 ~ mounted ~ canSend', canSend)
              if (canSend) {
                await LocalNotifications.schedule({
                  notifications: [
                    {
                      id: this.notificationId,
                      title: this.notificationDetailPerson,
                      channelId: this.channelNotification,
                      body: this.notificationDetail,
                      schedule: { at: new Date(Date.now() + 1500) },
                      actionTypeId: 'reply',
                      extra: { room: { ...record }, user: { ...this.user } },
                      smallIcon: 'ic_launcher_background',
                      attachments: [
                        {
                          id: 'imageAttachment',
                          url: 'https://cdn.britannica.com/78/6778-050-4D49E4F8/Borzoi.jpg',
                        },
                      ],
                    },
                  ],
                })

                this.notificationId++
              }
            }

            // if (this.getNotifAllOrAssigned.data == 1 || this.getNotifAllOrAssigned == 1) {
            //   // all
            //   if (
            //     this.userGetNotifSound() &&
            //     !this.notifications.find(x => x.id === data.record.lastMessage.source_id) &&
            //     this.currentRouteName !== 'inbox' &&
            //     this.currentRouteName !== 'inbox-phone-number'
            //   ) {
            //     this.play()
            //     this.lastNotif = new Date().getTime() / 1000
            //     this.notifMessageDetail = true
            //     this.$store.commit('inbox/setNewChat', true)
            //   }
            //   const notifications = JSON.parse(JSON.stringify(this.$store.getters['inbox/getNotifications']))
            //   // notifications.unshift(message)
            //   this.$store.commit('inbox/setNotifications', notifications)
            //   // this.notifications.unshift(message)
            // } else if (this.getNotifAllOrAssigned.data == 2 || this.getNotifAllOrAssigned == 2) {
            //   //! Active Channel
            //   if (this.getGlobalChannel) {
            //     if (this.getGlobalChannel.value.all_sender) {
            //       if (
            //         this.userGetNotifSound() &&
            //         !this.notifications.find(x => x.id === data.record.lastMessage.source_id) &&
            //         this.currentRouteName !== 'inbox' &&
            //         this.currentRouteName !== 'inbox-phone-number'
            //       ) {
            //         this.play()
            //         this.lastNotif = new Date().getTime() / 1000
            //         this.notifMessageDetail = true
            //         this.$store.commit('inbox/setNewChat', true)
            //       }
            //       const notifications = JSON.parse(JSON.stringify(this.$store.getters['inbox/getNotifications']))
            //       // notifications.unshift(message)
            //       this.$store.commit('inbox/setNotifications', notifications)
            //       // this.notifications.unshift(message)
            //     } else if (data.record.instance_id == this.getGlobalChannel.value._id) {
            //       if (
            //         this.userGetNotifSound() &&
            //         !this.notifications.find(x => x.id === data.record.lastMessage.source_id) &&
            //         this.currentRouteName !== 'inbox' &&
            //         this.currentRouteName !== 'inbox-phone-number'
            //       ) {
            //         this.play()
            //         this.lastNotif = new Date().getTime() / 1000
            //         this.notifMessageDetail = true
            //         this.$store.commit('inbox/setNewChat', true)
            //       }
            //       const notifications = JSON.parse(JSON.stringify(this.$store.getters['inbox/getNotifications']))
            //       // notifications.unshift(message)
            //       this.$store.commit('inbox/setNotifications', notifications)
            //       // this.notifications.unshift(message)
            //     }
            //   }
            // } else if (this.getNotifAllOrAssigned.data == 3 || this.getNotifAllOrAssigned == 3) {
            //   // assigned
            //   if (data.record.assign_to) {
            //     if (data.record.assign_to.email == this.user.email) {
            //       if (
            //         this.userGetNotifSound() &&
            //         !this.notifications.find(x => x.id === data.record.lastMessage.source_id) &&
            //         this.currentRouteName !== 'inbox' &&
            //         this.currentRouteName !== 'inbox-phone-number'
            //       ) {
            //         this.play()
            //         this.lastNotif = new Date().getTime() / 1000
            //         this.notifMessageDetail = true
            //         this.$store.commit('inbox/setNewChat', true)
            //       }
            //       const notifications = JSON.parse(JSON.stringify(this.$store.getters['inbox/getNotifications']))
            //       // notifications.unshift(message)
            //       this.$store.commit('inbox/setNotifications', notifications)
            //       // this.notifications.unshift(message)
            //     }
            //   }
            // }
          }
          if (this.user.chat_mode !== 'Read and Write to assigned only') {
            this.play()
            this.lastNotif = new Date().getTime() / 1000
            this.notifMessageDetail = true
            this.$store.commit('inbox/setNewChat', true)

            if (this.platform === 'web') {
              const canSend = await LocalNotifications.requestPermissions()
              console.log('🚀 ~ file: LayoutContentVerticalNav.vue ~ line 1336 ~ mounted ~ canSend', canSend)
              if (canSend) {
                await LocalNotifications.schedule({
                  notifications: [
                    {
                      id: this.notificationId,
                      title: this.notificationDetailPerson,
                      channelId: this.channelNotification,
                      body: this.notificationDetail,
                      schedule: { at: new Date(Date.now() + 1500) },
                      actionTypeId: 'reply',
                      extra: { room: { ...record }, user: { ...this.user } },
                      smallIcon: 'ic_launcher_background',
                      attachments: [
                        {
                          id: 'imageAttachment',
                          url: 'https://cdn.britannica.com/78/6778-050-4D49E4F8/Borzoi.jpg',
                        },
                      ],
                    },
                  ],
                })

                this.notificationId++
              }
            }

            // if (this.getNotifAllOrAssigned.data == 1 || this.getNotifAllOrAssigned == 1) {
            //   // all
            //   if (
            //     this.userGetNotifSound() &&
            //     !this.notifications.find(x => x.id === data.record.lastMessage.source_id) &&
            //     this.currentRouteName !== 'inbox' &&
            //     this.currentRouteName !== 'inbox-phone-number'
            //   ) {
            //     this.play()
            //     this.lastNotif = new Date().getTime() / 1000
            //     this.notifMessageDetail = true
            //     this.$store.commit('inbox/setNewChat', true)
            //   }
            //   const notifications = JSON.parse(JSON.stringify(this.$store.getters['inbox/getNotifications']))
            //   // notifications.unshift(message)
            //   this.$store.commit('inbox/setNotifications', notifications)
            //   // this.notifications.unshift(message)
            // } else if (this.getNotifAllOrAssigned.data == 2 || this.getNotifAllOrAssigned == 2) {
            //   //! Active Channel
            //   if (this.getGlobalChannel) {
            //     if (this.getGlobalChannel.value.all_sender) {
            //       if (
            //         this.userGetNotifSound() &&
            //         !this.notifications.find(x => x.id === data.record.lastMessage.source_id) &&
            //         this.currentRouteName !== 'inbox' &&
            //         this.currentRouteName !== 'inbox-phone-number'
            //       ) {
            //         this.play()
            //         this.lastNotif = new Date().getTime() / 1000
            //         this.notifMessageDetail = true
            //         this.$store.commit('inbox/setNewChat', true)
            //       }
            //       const notifications = JSON.parse(JSON.stringify(this.$store.getters['inbox/getNotifications']))
            //       // notifications.unshift(message)
            //       this.$store.commit('inbox/setNotifications', notifications)
            //       // this.notifications.unshift(message)
            //     } else if (data.record.instance_id == this.getGlobalChannel.value._id) {
            //       if (
            //         this.userGetNotifSound() &&
            //         !this.notifications.find(x => x.id === data.record.lastMessage.source_id) &&
            //         this.currentRouteName !== 'inbox' &&
            //         this.currentRouteName !== 'inbox-phone-number'
            //       ) {
            //         this.play()
            //         this.lastNotif = new Date().getTime() / 1000
            //         this.notifMessageDetail = true
            //         this.$store.commit('inbox/setNewChat', true)
            //       }
            //       const notifications = JSON.parse(JSON.stringify(this.$store.getters['inbox/getNotifications']))
            //       // notifications.unshift(message)
            //       this.$store.commit('inbox/setNotifications', notifications)
            //       // this.notifications.unshift(message)
            //     }
            //   }
            // } else if (this.getNotifAllOrAssigned.data == 3 || this.getNotifAllOrAssigned == 3) {
            //   // assigned
            //   if (data.record.assign_to) {
            //     if (data.record.assign_to.email == this.user.email) {
            //       if (
            //         this.userGetNotifSound() &&
            //         !this.notifications.find(x => x.id === data.record.lastMessage.source_id) &&
            //         this.currentRouteName !== 'inbox' &&
            //         this.currentRouteName !== 'inbox-phone-number'
            //       ) {
            //         this.play()
            //         this.lastNotif = new Date().getTime() / 1000
            //         this.notifMessageDetail = true
            //         this.$store.commit('inbox/setNewChat', true)
            //       }
            //       const notifications = JSON.parse(JSON.stringify(this.$store.getters['inbox/getNotifications']))
            //       // notifications.unshift(message)
            //       this.$store.commit('inbox/setNotifications', notifications)
            //       // this.notifications.unshift(message)
            //     }
            //   }
            // }
          }
        }
      }
    },
    getContactName(phoneNumber, roomName) {
      // const userData = await this.$store.dispatch('inboxNavbar/getUserData', {
      //   phone_number: phoneNumber,
      //   sub_id: this.user.sub_id,
      //   token: this.user.token,
      // })

      const userData = this.allContactData.find(contact => contact.phone_number == phoneNumber)
      // //console.log(userData)

      if (this.contactsPouch?.length) {
        const foundContact = this.contactsPouch.find(x => x.phone_number === phoneNumber)


        if (foundContact) {
          return foundContact.name
        }
      }

      if (userData) {
        if (userData.bailey_name) {
          return userData.bailey_name
        }

        if (userData.notify_name) {
          return userData.notify_name
        }

        if (userData.contact_list_name) {
          return userData.contact_list_name
        }

        if (userData.username) {
          return userData.username
        }
      }
      if (roomName !== phoneNumber) {
        return roomName
      }

      return this.formatPhoneNumber(phoneNumber)
    },
    async skipLoad() {
      // const loadingSyncPg = await this.$store.dispatch('global/getSettingsByKey', {
      //   sub_id: this.user.sub_id,
      //   token: this.user.token,
      //   key: 'loading_sync',
      // })

      // if (loadingSyncPg.status) {
      //   if (this.currentScanned !== null) {
      //     for (let loadSync of loadingSyncPg.data.value) {
      //       if (loadSync.instance_id === this.currentScanned.instance_id) {
      //         loadSync.loading_sync_contacts = false
      //         loadSync.loading_sync_history = false
      //         loadSync.progress = 100
      //         loadSync.skip = true
      //       }
      //     }
      //   } else {
      //     loadingSyncPg.data.value = []
      //   }
      //   // //console.log(loadingSyncPg.data, this.syncProgress, this.currentScanned, `INI LOAD`)
      //   await this.$store.dispatch('global/updateSettingsByKey', {
      //     sub_id: this.user.sub_id,
      //     token: this.user.token,
      //     key: 'loading_sync',
      //     value: loadingSyncPg.data.value,
      //   })
      // }
      this.checkIsSyncRoom = false
      this.progress = 0
      // this.$store.commit('global/setIsScanning', false)
      // this.$store.commit('inbox/setLastInserted', true)

      // const instanceIds = await this.$store.dispatch('auth/getAllInstances').value.map(el => this.$Md5Hash(el._id))
      // //console.log(instanceIds, 'ini instancevvv<<<')
      // await this.$store.dispatch('global/findAllContactData', {
      //   sub_id: this.user.sub_id,
      //   instances: instanceIds,
      // })
      this.progressText = 'Synchronizing to WhatsApp'
      window.location.reload()
    },
    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber?.includes('-') && phoneNumber?.length <= 16) {
        const countryCode = `+${phoneNumber.substr(0, 2)}` // +62
        const splitOne = phoneNumber.substr(2, 3)
        const splitTwo = phoneNumber.substr(5, 4)
        const splitThree = phoneNumber.substr(9, phoneNumber.length - 1)
        phoneNumber = `${countryCode} ${splitOne}-${splitTwo}-${splitThree}`
      }

      return phoneNumber
    },
    formatTags(content, room) {
      if (!content) {
        content = ''
      }

      // //console.log('room : ', room)

      const firstTag = '<usertag>'
      const secondTag = '</usertag>'
      let usertags = []

      if (content.includes('@')) {
        let split = content.split(' ')

        for (let i = 0; i < split.length; i++) {
          const word = split[i]
          if (word.startsWith('@')) {
            let newWord = new RegExp(word, 'g')
            let used = word.slice(1)
            content = content.replace(newWord, `${firstTag}${used}${secondTag}`)
          }
        }
      }

      usertags = [...content.matchAll(new RegExp(firstTag, 'gi'))].map(a => a.index)

      const initialContent = content
      // //console.log(content, usertags, `INI KONTEN`)
      if (usertags && usertags.length) {
        usertags.forEach(index => {
          const userId = initialContent.substring(index + firstTag.length, initialContent.indexOf(secondTag, index))

          // //console.log(userId, `INI USERTAGS`)

          const userData = room.users.find(userInfo => userInfo._id === userId)
          const user = this.allContactData.find(user => user.phone_number == userId)
          const userContact = this.contactsPouch.find(contact => contact.phone_number === userId)

          // //console.log(userId, userContact, `INI USER CONTACT`)

          // //console.log(userId, this.allContacts, `INI USER ID`)
          // //console.log(user, `INI USER`)

          if (user) {
            content = content.replaceAll(firstTag + userId + secondTag, `@${user.name ? user.name : userId}`)
          } else if (userData) {
            if (userData._id == userData.username.replace(/[+ -]/g, '')) {
              userData.username = this.formatPhoneNumber(userData._id, false)
            }

            content = content.replaceAll(
              firstTag + userId + secondTag,
              `@${userData.username ? userData.username : this.formatPhoneNumber(userData._id, false)}`,
            )
          } else {
            content = content.replaceAll(firstTag + userId + secondTag, `@${userId}`)
          }

          // format phone number
        })
      }

      // //console.log(content, `INI KONTEN`)

      return content
    },
    filterLabel() {
      // //console.log(this.selectedLabel, `INI SELECTED LABS`)
      this.$store.commit('inbox/setLabelFilter', this.selectedLabel)
    },
    async pingOps() {
      if (this.user.role !== 'Admin' && this.statusOperator === true && this.user.auto_assign_new_chat_setting) {
        // //console.log('aaaaaaaaaaaaamasukkkkk')
        // const pingOps = await this.$store.dispatch('rooms/pingOperator', {
        //   status: this.statusOperator,
        //   email: this.user.email,
        //   subId: this.user.sub_id,
        // })
        // if (pingOps.data.status) {
        //   let findOps = pingOps.data.data.find(el => el.email === this.user.email)
        //   this.$store.commit('rooms/setstatusOperator', findOps.status)
        // }
      }
    },
    async updateRooms(data) {
      // //console.log('masuk')
      if (data.record) {
        data.record.archived = data.record.archived && data.record.archived == 't'
        data.record.pinned = data.record.pinned && data.record.pinned == 't'
        data.record.sync_firestore = data.record.sync_firestore && data.record.sync_firestore == 't'
        data.record.unreplied = data.record.unreplied && data.record.unreplied == 't'
      }

      let next = true
      if (this.user.view_assign_only) {
        next = false
        if (data.record.assign_to && data.record.assign_to.email === this.user.email) {
          next = true
        }
      }
      // if (!this.user.view_assign_only) {
      //   next = false
      //   if (data.record.assign_to && data.record.assign_to.email === this.user.email) {
      //     next = true
      //   }
      // }
      if (this.user.limited_channel) {
        next = false
        if (this.user.enabled_channel && this.user.enabled_channel.find(y => y === data.record.instance_id)) {
          next = true
        }
      }

      if (next) {
        if (data.record.lastMessage) {
          const indexChat = this.allChatsCouch.findIndex(x => (x.roomId = data.record.roomId))
          let newChat = true
          if (indexChat > -1 && this.allChatsCouch[indexChat] && this.allChatsCouch[indexChat].lastMessage) {
            newChat = this.allChatsCouch[indexChat].lastMessage.source_id !== data.record.lastMessage.source_id
          }
          let standartTime = data.record.lastMessage.timestamp._seconds * 1000
          let timeNow = new Date().getTime() - 60000
          // //console.log(true, this.getNotifAllOrAssigned, timeNow <= standartTime, this.loadingMessages, `MASUK ADMIN`)

          if (
            // data.record.lastMessage.content_notification &&
            !data.record.lastMessage.fromMe &&
            this.user.package !== 'basic' &&
            newChat
            //  && timeNow <= standartTime
          ) {
            if (!this.notifications.find(x => x.id === data.record.lastMessage.source_id)) {
              let content = data.record.lastMessage.content
              this.mediaNotif = ''

              // if (data.record.lastMessage.content_notification) {
              //   content = data.record.lastMessage.content_notification
              // }

              if (
                data.record.lastMessage.files.length !== 0 &&
                data.record.lastMessage.files.find(el => el.type === 'webp')
              ) {
                this.mediaNotif = 'Sticker'
              } else if (
                data.record.lastMessage.files.length !== 0 &&
                data.record.lastMessage.files.find(el => el.name === 'Photo') &&
                data.record.lastMessage.files.find(el => !el.audio)
              ) {
                this.mediaNotif = 'Photo'
              } else if (
                data.record.lastMessage.files.length !== 0 &&
                data.record.lastMessage.files.find(el => el.name === 'Video')
              ) {
                this.mediaNotif = 'Video'
              } else if (
                data.record.lastMessage.files.length !== 0 &&
                data.record.lastMessage.data.type === 'document'
              ) {
                this.mediaNotif = 'Document'
              } else if (
                data.record.lastMessage.files.length !== 0 &&
                data.record.lastMessage.files.find(el => el.name === 'Audio') &&
                data.record.lastMessage.files.find(el => el.audio)
              ) {
                this.mediaNotif = 'Audio'
              }
              this.notificationDetailPerson = this.getContactName(data.record.phone_number, data.record.name)
              this.notificationDetail = this.formatTags(content, data.record) || this.mediaNotif
              this.notificationDetailPhoneNumber = data.record.lastMessage.sender_id
              this.roomId = data.record.roomId

              const message = {
                id: data.record.lastMessage.source_id,
                _id: data.record.lastMessage.source_id,
                roomId: data.record.roomId,
                user: {
                  avatar: data.record.profile_picture,
                  name: data.record.lastMessage.sender_name,
                },
                title: data.record.lastMessage.sender_name,
                subtitle: content === '' ? this.mediaNotif : this.formatTags(content, data.record), // this.formatTags(notification.caption, notification.users),
                mediaNotif: this.mediaNotif,
                time:
                  data.record.lastMessage &&
                  data.record.lastMessage.timestamp &&
                  data.record.lastMessage.timestamp._seconds
                    ? this.$moment(data.record.lastMessage.timestamp._seconds * 1000).format('HH:mm')
                    : '',
                messageTimestamp:
                  data.record.lastMessage &&
                  data.record.lastMessage.timestamp &&
                  data.record.lastMessage.timestamp._seconds
                    ? data.record.lastMessage.timestamp._seconds
                    : '',
                color: '', //notification.instance.color,
              }
              // //console.log(
              //   data.record,
              //   this.loadingMessages,

              //   `INI DI LAYOUT`,
              // )

              if (
                !data.record.lastMessage.fromMe &&
                !this.loadingMessages &&
                // new Date().getTime() === data.record.lastMessage.timestamp._seconds * 1000
                new Date().getTime() - data.record.lastMessage.timestamp._seconds * 1000 <= 60000
              ) {
                if (
                  this.user.role !== 'Admin' &&
                  (!data.record.assign_to || (data.record.assign_to && data.record.assign_to.email === this.user.email))
                ) {
                  if (this.getNotifAllOrAssigned.data == 1 || this.getNotifAllOrAssigned == 1) {
                    // all
                    if (
                      this.userGetNotifSound() &&
                      !this.notifications.find(x => x.id === data.record.lastMessage.source_id) &&
                      this.currentRouteName !== 'inbox' &&
                      this.currentRouteName !== 'inbox-phone-number'
                    ) {
                      this.play()
                      this.lastNotif = new Date().getTime() / 1000
                      this.notifMessageDetail = true
                      this.$store.commit('inbox/setNewChat', true)
                    }
                    const notifications = JSON.parse(JSON.stringify(this.$store.getters['inbox/getNotifications']))
                    // notifications.unshift(message)
                    this.$store.commit('inbox/setNotifications', notifications)
                    // this.notifications.unshift(message)
                  } else if (this.getNotifAllOrAssigned.data == 2 || this.getNotifAllOrAssigned == 2) {
                    //! Active Channel
                    if (this.getGlobalChannel) {
                      if (this.getGlobalChannel.value.all_sender) {
                        if (
                          this.userGetNotifSound() &&
                          !this.notifications.find(x => x.id === data.record.lastMessage.source_id) &&
                          this.currentRouteName !== 'inbox' &&
                          this.currentRouteName !== 'inbox-phone-number'
                        ) {
                          this.play()
                          this.lastNotif = new Date().getTime() / 1000
                          this.notifMessageDetail = true
                          this.$store.commit('inbox/setNewChat', true)
                        }
                        const notifications = JSON.parse(JSON.stringify(this.$store.getters['inbox/getNotifications']))
                        // notifications.unshift(message)
                        this.$store.commit('inbox/setNotifications', notifications)
                        // this.notifications.unshift(message)
                      } else if (data.record.instance_id == this.getGlobalChannel.value._id) {
                        if (
                          this.userGetNotifSound() &&
                          !this.notifications.find(x => x.id === data.record.lastMessage.source_id) &&
                          this.currentRouteName !== 'inbox' &&
                          this.currentRouteName !== 'inbox-phone-number'
                        ) {
                          this.play()
                          this.lastNotif = new Date().getTime() / 1000
                          this.notifMessageDetail = true
                          this.$store.commit('inbox/setNewChat', true)
                        }
                        const notifications = JSON.parse(JSON.stringify(this.$store.getters['inbox/getNotifications']))
                        // notifications.unshift(message)
                        this.$store.commit('inbox/setNotifications', notifications)
                        // this.notifications.unshift(message)
                      }
                    }
                  } else if (this.getNotifAllOrAssigned.data == 3 || this.getNotifAllOrAssigned == 3) {
                    // assigned
                    if (data.record.assign_to) {
                      if (data.record.assign_to.email == this.user.email) {
                        if (
                          this.userGetNotifSound() &&
                          !this.notifications.find(x => x.id === data.record.lastMessage.source_id) &&
                          this.currentRouteName !== 'inbox' &&
                          this.currentRouteName !== 'inbox-phone-number'
                        ) {
                          this.play()
                          this.lastNotif = new Date().getTime() / 1000
                          this.notifMessageDetail = true
                          this.$store.commit('inbox/setNewChat', true)
                        }
                        const notifications = JSON.parse(JSON.stringify(this.$store.getters['inbox/getNotifications']))
                        // notifications.unshift(message)
                        this.$store.commit('inbox/setNotifications', notifications)
                        // this.notifications.unshift(message)
                      }
                    }
                  }
                }
                if (this.user.role == 'Admin') {
                  if (this.getNotifAllOrAssigned.data == 1 || this.getNotifAllOrAssigned == 1) {
                    // all
                    if (
                      this.userGetNotifSound() &&
                      !this.notifications.find(x => x.id === data.record.lastMessage.source_id) &&
                      this.currentRouteName !== 'inbox' &&
                      this.currentRouteName !== 'inbox-phone-number'
                    ) {
                      this.play()
                      this.lastNotif = new Date().getTime() / 1000
                      this.notifMessageDetail = true
                      this.$store.commit('inbox/setNewChat', true)
                    }
                    const notifications = JSON.parse(JSON.stringify(this.$store.getters['inbox/getNotifications']))
                    // notifications.unshift(message)
                    this.$store.commit('inbox/setNotifications', notifications)
                    // this.notifications.unshift(message)
                  } else if (this.getNotifAllOrAssigned.data == 2 || this.getNotifAllOrAssigned == 2) {
                    //! Active Channel
                    if (this.getGlobalChannel) {
                      if (this.getGlobalChannel.value.all_sender) {
                        if (
                          this.userGetNotifSound() &&
                          !this.notifications.find(x => x.id === data.record.lastMessage.source_id) &&
                          this.currentRouteName !== 'inbox' &&
                          this.currentRouteName !== 'inbox-phone-number'
                        ) {
                          this.play()
                          this.lastNotif = new Date().getTime() / 1000
                          this.notifMessageDetail = true
                          this.$store.commit('inbox/setNewChat', true)
                        }
                        const notifications = JSON.parse(JSON.stringify(this.$store.getters['inbox/getNotifications']))
                        // notifications.unshift(message)
                        this.$store.commit('inbox/setNotifications', notifications)
                        // this.notifications.unshift(message)
                      } else if (data.record.instance_id == this.getGlobalChannel.value._id) {
                        if (
                          this.userGetNotifSound() &&
                          !this.notifications.find(x => x.id === data.record.lastMessage.source_id) &&
                          this.currentRouteName !== 'inbox' &&
                          this.currentRouteName !== 'inbox-phone-number'
                        ) {
                          this.play()
                          this.lastNotif = new Date().getTime() / 1000
                          this.notifMessageDetail = true
                          this.$store.commit('inbox/setNewChat', true)
                        }
                        const notifications = JSON.parse(JSON.stringify(this.$store.getters['inbox/getNotifications']))
                        // notifications.unshift(message)
                        this.$store.commit('inbox/setNotifications', notifications)
                        // this.notifications.unshift(message)
                      }
                    }
                  } else if (this.getNotifAllOrAssigned.data == 3 || this.getNotifAllOrAssigned == 3) {
                    // assigned
                    if (data.record.assign_to) {
                      if (data.record.assign_to.email == this.user.email) {
                        if (
                          this.userGetNotifSound() &&
                          !this.notifications.find(x => x.id === data.record.lastMessage.source_id) &&
                          this.currentRouteName !== 'inbox' &&
                          this.currentRouteName !== 'inbox-phone-number'
                        ) {
                          this.play()
                          this.lastNotif = new Date().getTime() / 1000
                          this.notifMessageDetail = true
                          this.$store.commit('inbox/setNewChat', true)
                        }
                        const notifications = JSON.parse(JSON.stringify(this.$store.getters['inbox/getNotifications']))
                        // notifications.unshift(message)
                        this.$store.commit('inbox/setNotifications', notifications)
                        // this.notifications.unshift(message)
                      }
                    }
                  }
                }

                if (this.getAutoAssignNewChat) {
                  await this.$store.dispatch('auth/requestAutoAssign', {
                    token: this.user.token,
                    id_phone: data.record._id,
                    sub_id: this.user.sub_id,
                  })
                }
              }
            }
          }
        }
      }
    },
    async updateSettingInsert(data) {
      // //console.log(this.loadingMessages, `INI LOAD MSG PAS ISNERT`)
      // if (this.loadingMessages) {
      //   this.checkIsSyncRoom = true
      // }
      // if (data && data.record && data.record.key == 'loading_sync') {
      //   // this.$store.commit('global/setLoadingMessages', true)
      //   if (data.record.value) {
      //     data.record.value = JSON.parse(data.record.value)
      //   }
      //   this.loadingSync = data.record
      //   if (this.loadingSync.length !== 0 && this.currentScanned !== null) {
      //     await this.checkInstances()
      //     const progressCount = this.loadingSync.find(data => data.instance_id === this.currentScanned._id)
      //     // //console.log(
      //     //   '🚀 ~ file: LayoutContentVerticalNav.vue ~ line 2094 ~ updateSetting ~ progressCount',
      //     //   progressCount,
      //     // )
      //     if (progressCount && this.progress < 100) {
      //       if (progressCount?.loading_sync_contacts === false && this.progress === 0) {
      //         this.progress += 20
      //         this.progressText = 'Synchronizing Contacts'
      //       }
      //       if (progressCount?.progress === 100) {
      //         this.progress += 60
      //         this.progressText = 'Downloading Messages'
      //       }
      //       if (progressCount?.loading_sync_history === false) {
      //         this.progress += 20
      //         this.progressText = 'Downloading Messages'
      //       }
      //       // }, 3000)
      //       if (this.progress === 100) {
      //         this.checkIsSyncRoom = false
      //         this.$store.commit('global/setLoadingMessages', false)
      //         this.$store.commit('global/setCurrentScanned', null)
      //         this.progress === 0
      //         this.progressText = 'Synchronizing to WhatsApp'
      //       } else {
      //         this.checkIsSyncRoom = true
      //       }
      //     }
      //   }
      // }
    },
    async updateSetting(data) {
      // //console.log(this.loadingMessages, data.record.key, `INI LOADING MESSAGES`)

      // if (this.loadingMessages) {
      //   this.checkIsSyncRoom = true
      // }
      if (data && data.record && data.record.key == 'instances') {
        if (data.record.value) {
          data.record.value = JSON.parse(data.record.value)
        }
        // //console.log(data.record, 'channelsmd2')
        // this.instances = data.record
        // this.$store.commit('auth/setListInstance', this.instances)
        // await this.checkInstances()
      }

      if (data && data.record && data.record.key == 'loading_sync') {
        // this.$store.commit('global/setLoadingMessages', true)
        if (data.record.value) {
          data.record.value = JSON.parse(data.record.value)
        }
        this.loadingSync = data.record.value
        // //console.log('this.loadingSync: ', this.loadingSync)

        if (this.loadingSync.length !== 0 && this.currentScanned !== null) {
          await this.checkInstances()
          const progressCount = this.loadingSync.find(data => data.instance_id === this.currentScanned._id)
          // //console.log(
          //   '🚀 ~ file: LayoutContentVerticalNav.vue ~ line 2094 ~ updateSetting ~ progressCount',
          //   progressCount,
          // )
          if (progressCount && this.progress < 100) {
            if (progressCount?.loading_sync_contacts === false && this.progress === 0) {
              this.progress += 20
              this.progressText = 'Synchronizing Contacts'
            }
            if (progressCount?.progress === 100) {
              this.progress += 60
              this.progressText = 'Downloading Messages'
            }
            if (progressCount?.loading_sync_history === false) {
              this.progress += 20
              this.progressText = 'Downloading Messages'
            }
            // }, 3000)

            if (this.progress === 100) {
              this.checkIsSyncRoom = false
              this.$store.commit('global/setLoadingMessages', false)
              this.$store.commit('global/setCurrentScanned', null)
              this.progress = 0
              this.progressText = 'Synchronizing to WhatsApp'
            } else {
              this.checkIsSyncRoom = true
            }
          }
          // //console.log(this.progressText, 'ini text<<<')
        }
      }
    },
    async resetNotifications() {
      this.notifications = []
      // await this.goToInbox()
    },
    async deleteNotifications(data) {
      this.notifications = this.notifications.filter(notification => notification.roomId !== data.roomId)
    },
    async saveChatbotSetting() {
      // //console.log(this.chatbotSetting, `INI HASIL`)
      const payload = JSON.parse(JSON.stringify(this.chatbotSetting))
      payload.subId = this.user.sub_id

      const res = await this.$store.dispatch('eOrdering/registerChatbot', payload)

      // //console.log(payload, res, `INI PAYLOAD`)
      this.dialogChatbotSetting = false
      this.chatbotSetting = {
        greetingMsg: '',
        talkToCustMsg: '',
        selectedShop: null,
        qnaMsg: '',
        instanceId: '',
        keyword: '',
        keywordActive: false,
        isActive: true,
      }
    },
    closeChatbotSetting() {
      this.dialogChatbotSetting = false
      this.chatbotSetting = {
        greetingMsg: '',
        talkToCustMsg: '',
        selectedShop: null,
        qnaMsg: '',
        instanceId: '',
        keyword: '',
        keywordActive: false,
        isActive: true,
      }
    },
    async openAddNewLink() {
      this.shopData = null
      // this.$refs.addorder.form = {
      //   name: '',
      //   tax: 0,
      //   enableTax: false,
      //   address: {
      //     enableDeliveryPricing: false,
      //     city: {},
      //     province: {},
      //     subdistrict: {},
      //     detail: '',
      //     availableCourier: [],
      //   },
      //   urlGenerate: [],
      //   excelId: '',
      //   shopType: {},
      //   phoneNumber: '',
      //   selectedContactList: {},
      //   pricing: {
      //     enableCash: true,
      //     enableXendit: false,
      //     enablePaypal: false,
      //     keyXendit: '',
      //     keyPaypal: '',
      //   },
      //   driveInfo: {
      //     kind: '',
      //     id: '',
      //     name: '',
      //     mimeType: '',
      //     link: '',
      //   },
      // }
      this.addEorder = true
    },
    async openChatbotSetting() {
      const res = await this.$store.dispatch('eOrdering/getChatbot', { subId: this.user.sub_id })
      await this.getShop({ sub_id: this.user.sub_id })

      if (res.status !== 'failed') {
        this.chatbotSetting = res.data
        // //console.log(res.data, 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
      }

      this.dialogChatbotSetting = true
    },
    async closeModalCheck(e) {
      if (e) {
        this.$store.commit('eOrdering/setCloseModal', true)
        // await this.getShop({ sub_id: this.user.sub_id })
        // this.getDateandMenu()
      }
      this.addEorder = false
      // this.editEorder = false
    },
    truncateNotifMessage(notificationDetail) {
      if (this.$vuetify.breakpoint.xs) {
        if (notificationDetail.length <= 50) return notificationDetail.slice(0, 50)

        return `${notificationDetail.slice(0, 50)}...`
      }
      if (notificationDetail.length <= 100) {
        return `${notificationDetail.slice(0, 100)}`
      }

      return `${notificationDetail.slice(0, 100)}...`
    },
    truncateChannelLabel(channel) {
      if (this.$vuetify.breakpoint.xs) {
        if (channel.length <= 3) return channel.slice(0, 3)
        else return channel.slice(0, 3) + '..'
      }
      return channel
    },
    changeInstance(value) {
      // //console.log(value, 'ini value<< real')
      this.$store.commit('inboxNavbar/setValueChannel', value)
      this.$store.commit('global/setGlobalChannel', value)
    },

    async getSubscriptions(fromFreeTrial = false) {
      const resp = await this.$store.dispatch('subscriptions/fetchSubscriptions', {
        email: this.user.email,
        sub_id: this.user.sub_id,
      })

      // //console.log(
      //   resp,
      //   this.user,
      //   fromFreeTrial,
      //   this.$moment().isBefore(this.$moment(+resp.data.dataValues.expire_date)),
      //   this.isGotoChannel,
      //   `INI RESPONSE NYA`,
      // )

      if (resp.status) {
        // after free trial button clicked
        // if (fromFreeTrial) {
        //   await this.callbackGetSubscriptions(resp, fromFreeTrial)
        // }

        if (
          resp.data.dataValues.package &&
          resp.data.dataValues.expire_date &&
          !resp.data.dataValues.is_free_trial &&
          !this.$moment().isBefore(this.$moment(+resp.data.dataValues.expire_date)) &&
          !this.isGotoChannel
        ) {
          this.logout()
          // //console.log(`masuk if 1`, true)
          // await this.callbackGetSubscriptions(resp)
        }

        // todo update is free trial to false
        if (
          resp.data.dataValues.package &&
          resp.data.dataValues.expire_date &&
          resp.data.dataValues.is_free_trial &&
          this.$moment().isAfter(this.$moment(+resp.data.dataValues.expire_date))
        ) {
          // //console.log(`masuk if 2`, false)
          this.logout()
          // await this.$store.dispatch('subscriptions/updateIsFreeTrial', {
          //   sub_id: resp.data.dataValues.sub_id,
          //   is_free_trial: false,
          // })

          // await this.$store.commit('auth/setUserObj', {
          //   key: 'is_free_trial',
          //   payload: false,
          // })
        }
      }
    },
    // async getIframeSrc(data) {
    //   //console.log(process.env.VUE_APP_WA_SERVICES_URL + data, 'data iframe')
    //   return process.env.VUE_APP_WA_SERVICES_URL + 'register-channel-pg/' + data
    // },
    async openNotifyChat() {
      // //console.log('bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb')
      if (this.instanceValuesConnected && this.instanceValuesConnected.length > 0) {
        // //console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
        // await this.fdbGetAllContactFromRoomNotify(this.user.sub_id, this.instanceValuesConnected)

        if (this.contactNeedReply > 0 && this.user.package !== 'basic' && this.user.package !== '') {
          this.notifMessage = true
          this.play()
        }
      }
    },
    setAlertChannelText() {
      // if (!this.instancesSetting) return
      const labels = []

      if (this.instanceValues) {
        this.instanceValues.value.forEach(instance => {
          if ((instance.status == 0 || instance.status == 3) && instance.phone_number) {
            if (this.user.limited_channel) {
              if (this.user.enabled_channel && this.user.enabled_channel.find(y => y === instance._id)) {
                labels.push(instance.label === '-' ? instance.label_server : instance.label)
              }
            } else {
              labels.push(instance.label === '-' ? instance.label_server : instance.label)
            }
          }
        })
      }

      if (labels.length > 0) {
        if (
          this.$store.getters['inbox/getBannerAlertText'] !==
          `Channel ${labels.join(', ')} is not connected, please check your device connection`
        ) {
          return this.$store.commit(
            'inbox/setBannerAlertText',
            `Channel ${labels.join(', ')} is not connected, please check your device connection`,
          )
        }
      } else {
        this.$store.commit('inbox/setBannerAlertText', ``)
      }

      return
    },
    onFreeTrialEnd() {
      window.location.reload()
    },
    getChannelPhone(id) {
      let listInstance = this.$store.getters['auth/getListInstance']
      if (listInstance.length > 0) {
        const obj = listInstance.find(x => x._id === id)
        return obj ? obj.phone_number : '-'
      }
      return '-'
    },
    userGetNotifSound() {
      if (!(this.lastNotif === null || new Date().getTime() / 1000 - this.lastNotif > 5)) return false
      // if (!(!notification.contact.assign_to || notification.contact.assign_to.email === this.user.email)) return false

      // if (this.user.limited_channel) {
      //   let getNotif = false
      //   this.user.enabled_channel.forEach(channel_id => {
      //     if (notification.contact.ch) {
      //       const keys = Object.keys(notification.contact.ch)
      //       if (keys.includes(this.getChannelPhone(channel_id))) {
      //         getNotif = true
      //       }
      //     }
      //   })

      //   return getNotif
      // }
      if (this.user.package === 'basic') return false

      return true
    },
    logout(expiredSession = false) {
      // this.$store.dispatch('auth/setOnlineStatus', {
      //   token: this.user.token,
      //   subId: this.user.sub_id,
      //   email: this.user.email,
      //   online: false,
      // })
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
      setTimeout(function () {
        window.location.reload()
      }, 500)

      if (expiredSession) {
        setTimeout(function () {
          this.expiredMessage = true
        }, 2000)
      }
    },
    handler: async function handler(event) {
      // this.$store.dispatch('auth/setOnlineStatus', {
      //   token: this.user.token,
      //   subId: this.user.sub_id,
      //   email: this.user.email,
      //   online: false,
      // })
    },
    async setOnlineFalse() {
      // await this.$store.dispatch('auth/setOnlineStatus', {
      //   token: this.user.token,
      //   subId: this.user.sub_id,
      //   email: this.user.email,
      //   online: false,
      // })
    },
    // formatTags(content, users) {
    //   const firstTag = '<usertag>'
    //   const secondTag = '</usertag>'

    //   const usertags = [...content.matchAll(new RegExp(firstTag, 'gi'))].map(a => a.index)

    //   const initialContent = content

    //   usertags.forEach(index => {
    //     const userId = initialContent.substring(index + firstTag.length, initialContent.indexOf(secondTag, index))

    //     const user = users.find(user => user._id === userId)

    //     content = content.replaceAll(userId, `@${user?.username}`)
    //   })

    //   // remove <usertag></usertag>
    //   const usertagOpenRegex = /<usertag>/g
    //   const usertagCloseRegex = /<\/usertag>/g
    //   content = content.replace(usertagOpenRegex, '')
    //   content = content.replace(usertagCloseRegex, '')

    //   return content
    // },
    goToInbox() {
      this.$router.push({
        name: 'inbox',
        params: {
          filterName: 'Unreplied',
        },
      })
    },
    goToRoomInbox() {
      // //console.log('Masuk')
      // //console.log(this.roomId, `INI ID ROOM`)
      // if (this.roomId) {
      this.$router.push({
        name: 'inbox',
        params: {
          roomId: this.roomId,
        },
      })
      this.notifMessageDetail = false
      // }
    },
    play() {
      const sound = require('@/assets/sound/ting.wav')
      const audio = new Audio(sound)
      const resp = audio.play()

      if (resp !== undefined) {
        resp
          .then(_ => {
            // autoplay starts!
          })
          .catch(error => {
            //show error
            //console.log('🚀 ~ file: LayoutContentVerticalNav.vue ~ line 2731 ~ play ~ error', error)
          })
      }
    },
    async setDefaultNotif() {
      let unrepliedChats = this.allChatsCouch.filter(x => {
        return x.unreplied !== undefined && x.unreplied === true && x.archived !== 1
      })
      unrepliedChats = _orderBy(unrepliedChats, 'last_message', 'desc')
      let totalNotif = 0
      if (unrepliedChats.length > 0) {
        for (var data of unrepliedChats) {
          // //console.log(data.lastMessage, `INI LAST MSG`)
          if (totalNotif === 5) break
          if (
            data.lastMessage &&
            new Date().getTime() - data.lastMessage.timestamp._seconds * 1000 <= 60000 &&
            !data.lastMessage.seen
          ) {
            let content = data.lastMessage.content

            // if (data.lastMessage.content_notification) {
            //   content = data.lastMessage.content_notification
            // }
            let mediaNotif = ''

            if (data.lastMessage.files.length !== 0 && data.lastMessage.files.find(el => el.type === 'webp')) {
              mediaNotif = 'Sticker'
            } else if (
              data.lastMessage.files.length !== 0 &&
              data.lastMessage.files.find(el => el.name === 'Photo') &&
              data.lastMessage.files.find(el => !el.audio)
            ) {
              mediaNotif = 'Photo'
            } else if (data.lastMessage.files.length !== 0 && data.lastMessage.files.find(el => el.name === 'Video')) {
              mediaNotif = 'Video'
            } else if (
              data.lastMessage.files.length !== 0 &&
              data.lastMessage.files.find(el => el.name === 'Audio') &&
              data.lastMessage.files.find(el => el.audio)
            ) {
              mediaNotif = 'Audio'
            } else if (
              data.lastMessage.files.length !== 0 &&
              data.lastMessage.files.find(el => el.name === 'Document') &&
              data.lastMessage.files.find(el => el.document)
            ) {
              mediaNotif = 'Document'
            }
            const notification = {
              id: data.lastMessage.source_id,
              _id: data.lastMessage.source_id,
              roomId: data.roomId,
              user: {
                avatar: data.profile_picture,
                name: data.lastMessage.sender_name,
              },
              title: data.lastMessage.sender_name,
              subtitle: content === '' ? mediaNotif : this.formatTags(content, data), // this.formatTags(notification.caption, notification.users),
              mediaNotif: mediaNotif,
              time:
                data.lastMessage && data.lastMessage.timestamp && data.lastMessage.timestamp._seconds
                  ? this.$moment(data.lastMessage.timestamp._seconds * 1000).format('HH:mm')
                  : '',
              messageTimestamp:
                data.lastMessage && data.lastMessage.timestamp && data.lastMessage.timestamp._seconds
                  ? data.lastMessage.timestamp._seconds
                  : '',
              color: '', //notification.instance.color,
            }
            if (notification) {
              if (!data.lastMessage.fromMe) {
                const exists = this.notifications.find(item => item.id == notification.id)
                if (!exists) {
                  const notifications = JSON.parse(JSON.stringify(this.$store.getters['inbox/getNotifications']))
                  // notifications.push(message)
                  this.$store.commit('inbox/setNotifications', notifications)
                  this.notifications.push(notification)
                  totalNotif = totalNotif + 1
                }
              }
            }
          }
          this.notifications = _orderBy(this.notifications, 'messageTimestamp', 'desc')
        }
      }
    },
    async checkHealth() {
      const conn = await this.checkStatusConnectionServer()
    },
    async setOnline() {
      // await this.$store.dispatch('auth/setOnlineStatus', {
      //   token: this.user.token,
      //   subId: this.user.sub_id,
      //   email: this.user.email,
      //   online: true,
      // })
    },
    async loadSetting() {
      // const items = await this.mixinLoadSettingsRemote()
      // this.instancesSetting = items.find(x => x.key === 'instances')
      // this.$store.commit('auth/setListInstance', this.instancesSetting)

      await this.checkInstances()
      const parent = this
      // this.intervalIdCheckHealth = window.setInterval(async () => {
      //   await parent.setOnline()
      //   await parent.checkHealth()
      // }, 5000)

      // this.intervalPingOps = window.setInterval(async () => {
      //   await parent.pingOps()
      // }, 60000)
      // if (this.selectedNotifUnreplied.time === 0) {
      //   if (this.notifyInterfall) clearInterval(this.notifyInterfall)
      // } else {
      //   this.notifyInterfall = window.setInterval(async () => {
      //     await parent.openNotifyChat()
      //   }, this.selectedNotifUnreplied.time)
      // }

      this.intervalId = window.setInterval(async () => {
        await parent.checkInstances()
      }, 60000)

      // if (this.instanceValues) {
      //   for (const data of this.instanceValues.value) {
      //     if (data.phone_number) {
      //       if (data.status != 0) {
      //         this.optionsInstances.push({
      //           label: data.label,
      //           value: data,
      //         })
      //       }
      //     }
      //   }
      // }

      // for (const data of this.instanceValues.value) {
      //   if (data.phone_number) {
      //     if (this.user.limited_channel) {
      //       if (this.user.enabled_channel.length > 0 && this.user.enabled_channel.includes(data._id)) {
      //         if (data.status != 0) {
      //           this.optionsInstances.push({
      //             label: data.label && data.label === '-' ? data.label_server : data.label,
      //             value: data,
      //           })
      //         }

      //         // this.statusInstancesList.push({
      //         //   _id: data._id,
      //         //   label: data.label && data.label === '-' ? data.label_server : data.label,
      //         //   status: data.status,
      //         //   model: false,
      //         // })
      //       }
      //     } else {
      //       if (data.status != 0) {
      //         this.optionsInstances.push({
      //           label: data.label && data.label === '-' ? data.label_server : data.label,
      //           value: data,
      //         })
      //       }

      //       // this.statusInstancesList.push({
      //       //   _id: data._id,
      //       //   label: data.label && data.label === '-' ? data.label_server : data.label,
      //       //   status: data.status,
      //       //   model: false,
      //       // })
      //     }
      //   }
      // }

      // //console.log(this.optionsInstances, `INI TESTTTTTTTTTTT`)

      // //console.log(this.getGlobalChannel, `INI CHANNEL`)
    },
    async checkInstances() {
      // if (this.instancesSetting) {
      let isChange = false
      if (this.instanceValues) {
        for (var data of this.instanceValues.value) {
          if (
            data.phone_number &&
            !data.hasOwnProperty('clientSecret') &&
            !data.hasOwnProperty('id_page') &&
            !data.hasOwnProperty('id_instagram') &&
            !data.hasOwnProperty('username') &&
            !data.hasOwnProperty('phone_number_id')
          ) {
            // const isReady = await this.$store.dispatch('auth/checkChannelReady', data.sub_id + '-' + data._id)
            // if (!isReady) {
            //   await this.$store.dispatch('auth/checkStatusMd', {
            //     id: data.sub_id + '-' + data._id,
            //     ready: isReady,
            //   })

            //   data.status = this.$store.getters['auth/getStatusInstance']
            //   isChange = true
            // } else {
            await this.$store.dispatch('auth/checkStatusMd', {
              id: data.sub_id + '-' + data._id,
              ready: true,
            })
            // //console.log('check status channel', data.sub_id + '-' + data._id)
            if (!data.label || data.label == '-') {
              data.label = data.label_server ? data.label_server : data.phone_number
              isChange = true
            }

            // //console.log(data.status, this.$store.getters['auth/getStatusInstance'])
            if (data.status !== this.$store.getters['auth/getStatusInstance']) {
              data.status = this.$store.getters['auth/getStatusInstance']
              isChange = true
            }
            // }
            // this.syncContactsWa(data)
          }
        }

        if (
          this.instanceValues &&
          this.instanceValues.value.filter(x => (x.status === 0 || x.status === 3) && x.phone_number).length > 0
        ) {
          // //console.log('masuk...............+++++++++++++')
          this.$store.dispatch('global/setStatusChannels', true)
        } else {
          // //console.log('masuk...............----------------------')
          this.$store.dispatch('global/setStatusChannels', false)
          this.$store.commit('inbox/setBannerAlertText', ``)
        }
      }
      // //console.log('masuk', this.instanceValues, isChange)
      if (isChange) {
        this.instanceValues.value.forEach(async x => {
          if (
            x.status !== 1 &&
            !x.hasOwnProperty('clientSecret') &&
            !x.hasOwnProperty('id_page') &&
            !x.hasOwnProperty('id_instagram') &&
            !data.hasOwnProperty('username') &&
            !x.hasOwnProperty('username') &&
            !x.hasOwnProperty('phone_number_id')
          ) {
            // await this.$store.dispatch('auth/startConnection', {
            //   id: x.sub_id + '-' + x._id,
            // })
          }
          if (x.status === 3 && this.instanceValues.value.length >= this.getIframeSrcCount) {
            // await this.getIframeSrc(x.sub_id + '-' + x._id)
            this.getIframeSrcCount += 1
          }
        })

        if (!this.$store.getters['auth/isResetChannels']) {
          // await this.$store.dispatch('global/updateSettingsByKey', {
          //   sub_id: this.user.sub_id,
          //   token: this.user.token,
          //   key: 'instances',
          //   value: this.instanceValues.value,
          // })
        }
        await this.$store.commit('auth/setIsResetChannels', false)
        // //console.log(this.user, `INI USER NYA`)
        // this.updateSettings(this.instanceValues)
      }
      // await this.$store.dispatch('global/updateSettingsByKey', {
      //   sub_id: this.user.sub_id,
      //   token: this.user.token,
      //   key: 'instances',
      //   value: this.instanceValues.value,
      // })
      // //console.log(this.instanceValues, `INI VALUE`)
      // this.$store.commit('auth/setListInstance', this.instanceValues)
      // }
    },
    reloadPage() {
      window.location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
.height-dialog {
  max-height: 400px;
  overflow-y: auto;
}
.v-select__slot {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.height-costum-alert {
  height: auto;
  //max-height: 150px;
  //overflow: hidden auto;
}
.sticky-card {
  width: 344px;
  position: fixed;
  top: 90px;
  z-index: 1;
  /* centering */
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.after-sticky {
  margin-top: 90px;
}
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
  flex-flow: row wrap;
}
.font-title-sneakbar {
  font-size: 18px;
  font-weight: 800;
  color: white !important;
}
.font-body-sneakbar {
  font-size: 14px;
  color: white !important;
}
.font-sneakbar {
  font-size: 18px;
  color: white !important;
}
.state-circle {
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin-right: 6px;
  transition: 0.3s;
}

.show-btn {
  display: none;
}

@media only screen and (max-width: 612px) {
  .remove-btn {
    display: none;
  }

  .show-btn {
    display: inherit;
  }
}

.size-col {
  margin-bottom: -20px;
}

@media (max-width: 500px) {
  .font-title-sneakbar {
    font-size: 16px;
    font-weight: 800;
    color: white !important;
  }
  .font-body-sneakbar {
    font-size: 12px;
    color: white !important;
  }
  .font-sneakbar {
    font-size: 16px;
    color: white !important;
  }
}
// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }
    > .d-flex > button.v-icon {
      display: none;
    }
    // ===
    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }
    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
